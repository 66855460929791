
import React, { useState} from 'react';
import { Modal, Button, Form } from 'semantic-ui-react';

const ConfirmationBox = props =>{
    const [ inputText, setInputText ] = useState(props.inputText);

    const onChange = (e) => {
        setInputText(e.target.value);
    }

    return (
        <Modal size={'mini'} open={props.open}>
            <Modal.Header>Confirmation</Modal.Header>
            <Modal.Content>
                {props.message}
                <p/>
                {(props.inputCaption!==null && props.inputCaption!==undefined) ? (
                    <Form>
                        <Form.Group widths='equal'>
                            <Form.Input fluid label={props.inputCaption} placeholder={props.inputCaption} value={inputText} onChange={onChange} />
                        </Form.Group>
                     </Form>
                )
                :
                    null
                }
            </Modal.Content>
            <Modal.Actions>
                <Button negative onClick={props.onNo}>No</Button>
                <Button positive icon='checkmark' labelPosition='left' content='Yes' onClick={() => props.onYes(inputText)}/>
            </Modal.Actions>
        </Modal>
    );
    
}

export default ConfirmationBox