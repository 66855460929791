import React, { useEffect, useState} from 'react';

import { Container,Button,Segment,List } from 'semantic-ui-react';

import { useHistory } from 'react-router-dom';
import URLLocation from '../services/URLLocation';

import { Link } from 'react-router-dom'

import usePostData from '../services/usePostData';
import useGetData from '../services/useGetData'
import useParallelGetData from '../services/useParallelGetData'

import LoadingBox from '../components/LoadingBox';
import ConfirmationBox from '../components/ConfirmationBox';
import MessageBox from '../components/MessageBox'

import useAuthContext from '../services/stores/useAuthContext';
import ModalData from './Modals/ModalData';

const Page1 = props => {
    const history = useHistory()
    const { stateContext } = useAuthContext()
    const { par1 } = props.match.params
    
    const { stateGet, setGetUrl, setRefreshGet } = useGetData(null,null)
    const { statePost, setPayload, setRefreshPost } = usePostData(URLLocation.getUrl() + '/createapp',null,null)
    const { stateGetParallel, setGetUrls, setRefreshParallelGet } = useParallelGetData(null,null)
    
    const [ openConf, setOpenConf ] = useState(false)
    const [ openErr, setOpenErr ] = useState(false)
    const [ errMsg, setErrMsg ] = useState(null)

    const [ dataGet, setDataGet ] = useState(null)
    const [ dataParallel1, setDataParallel1] = useState(null)
    const [ dataParallel2, setDataParallel2] = useState(null)
    const [ dataPost, setDataPost ] = useState(null)

    const [ flag, setFlag ] = useState(null)
    const [ isEdit, setIsEdit ] = useState(false)
    const [ idEdit, setIdEdit] = useState(null)
    const [ openModal, setOpenModal ] = useState(false)


    useEffect(()=>{
        alert('ini dipanggil setiap page ini dirender pertama kali saja')
    },[])

    //GET EFFECT
        useEffect(()=>{
            switch (stateGet.status) {
                case 'SUCCESS':
                    if (flag===1)
                        setDataGet(stateGet.data)
                    else
                        setDataPost(stateGet.data)
                    break;
                case 'FAILED':
                    setErrMsg(stateGet.errorMessage)
                    setOpenErr(true)
                    break;
                default:
                    break;
            }
        },[stateGet.afterfetch])

    //POST EFFECT
        useEffect(()=>{
            switch (statePost.status) {
                case 'SUCCESS':
                    setFlag(2)
                    setGetUrl(URLLocation.getUrl() + '/getapps/1')
                    setRefreshGet()
                    break;
                case 'FAILED':
                    setErrMsg(stateGet.errorMessage)
                    setOpenErr(true)
                    break;
                default:
                    break;
            }
        },[statePost.afterfetch])

    //PARALLEL GET EFFECT
        useEffect(()=>{
            switch (stateGetParallel.status) {
                case 'SUCCESS':
                    setDataParallel1(stateGetParallel.data[0])
                    setDataParallel2(stateGetParallel.data[1])
                    break;
                case 'FAILED':
                    setErrMsg(stateGet.errorMessage)
                    setOpenErr(true)
                    break;
                default:
                    break;
            }
        },[stateGetParallel.afterfetch])

        const openConfirmation =()=>{
            setOpenConf(true)
        }

        const handleConfirm = () =>{
            history.go('/home')
    
        }

    //GET
        const executeGet = () =>{
            setFlag(1)
            setGetUrl(URLLocation.getUrl() + '/getplants')
            setRefreshGet()
        }

        const handleClick = (name,idx) => {
            alert('Ini index ke-' + idx + ' dengan nama lokasi ' + name)
        }

    //PARALLEL GET
        const executeParallelGet = () => {
            const urls = [
                URLLocation.getUrl() + '/getplants',
                URLLocation.getUrl() + '/getapps/1'
            ]
            setGetUrls(urls)
            setRefreshParallelGet()
        }

        const clearDataParallel = () => {
            setDataParallel1(null)
            setDataParallel2(null)
        }

    //POST
        const executePost = () => {
            setPayload({
                appname : 'TEST 1',
                appdesc : 'TEST 1',
                appcode : 'TEST1', 
                is_active : 1, 
                dbserver : null, 
                app_url : 'http://localhost:3000'
            })
            setRefreshPost()
        }

    //MODAL
        const addApp = () => {
            setIsEdit(false)
            setIdEdit(null)
            setOpenModal(true)
        }
 
        const handleEdit = (id) => {
            setIsEdit(true)
            setIdEdit(id)
            setOpenModal(true)
        }

        const onCancel = () => {
            setIsEdit(false)
            setIdEdit(null)
            setOpenModal(false)
        }

        const onYes = () => {
            alert('ini habis edit')
            setIsEdit(false)
            setIdEdit(null)
            setOpenModal(false)
            setRefreshParallelGet()
        }
    

	return (
		<Container>
            <ModalData open={openModal} isEdit={isEdit} id={idEdit} onYes={onYes} onNo={onCancel} />
            <ConfirmationBox message='Konfirmasi mau beneran ?' open={openConf} onYes={handleConfirm} onNo={()=>setOpenConf(false)}/>
            <MessageBox open={openErr} message={errMsg} onClose={()=>setOpenErr(false)} />
			{(stateGet.isLoading || statePost.isLoading || stateGetParallel.isLoading) ?
                <LoadingBox/>
                :
                <>
                	halo {stateContext.partner_name}. Ini Page 1 di {par1} <p/>

                   

                    <Button size='mini' onClick={openConfirmation}>Ke home dengan konfirmasi</Button><p/>

                    <Button size='mini' onClick={executeGet}>Contoh GET</Button><p/>
                    <Button size='mini' onClick={() =>setDataGet(null)}>Clear data GET</Button>
                    <Segment>
                        <List divided verticalAlign='middle'>
                        {dataGet && dataGet.map((obj,index)=>{
                            return (
                                <List.Item>
                                    <List.Content>
                                        <List.Header><a as={Link} onClick={()=>handleClick(obj.name_coy,index)}>NAMA : {obj.name_coy}</a></List.Header>
                                        <p/>
                                        <List.Description>
                                        </List.Description>
                                    </List.Content>
                                </List.Item>
                            )
                        })}
                        </List>
                    </Segment><p/>

                    <Button size='mini' onClick={executeParallelGet}>Contoh PARALLEL GET</Button><p/>
                    <Button size='mini' onClick={clearDataParallel}>Clear data PARALLEL GET</Button>
                    <Segment>
                        <List divided verticalAlign='middle'>
                        {dataParallel1 && dataParallel1.map((obj,index)=>{
                            return (
                                <List.Item>
                                    <List.Content>
                                        <List.Header>NAMA LOKASI : {obj.name_coy}</List.Header>
                                        <p/>
                                        <List.Description>
                                        </List.Description>
                                    </List.Content>
                                </List.Item>
                            )
                        })}
                        </List>
                    </Segment><p/>
                    <Segment>
                        <List divided verticalAlign='middle'>
                        {dataParallel2 && dataParallel2.map((obj,index)=>{
                            return (
                                <List.Item>
                                    <List.Content>
                                        <List.Header><a as={Link} onClick={()=>handleEdit(obj._id)}>NAMA APP : {obj.appname}</a></List.Header>
                                        <p/>
                                        <List.Description>
                                        </List.Description>
                                    </List.Content>
                                </List.Item>
                            )
                        })}
                        </List>
                    </Segment>

                    <Button size='mini' onClick={addApp}>Add App dengan modal</Button><p/>

                    <Button size='mini' onClick={executePost}>Contoh POST</Button><p/>
                    <Button size='mini' onClick={()=>setDataPost(null)}>Clear data POST</Button>
                    <Segment>
                        <List divided verticalAlign='middle'>
                        {dataPost && dataPost.map((obj,index)=>{
                            return (
                                <List.Item>
                                    <List.Content>
                                        <List.Header>NAMA : {obj.appname}</List.Header>
                                        <p/>
                                        <List.Description>
                                        </List.Description>
                                    </List.Content>
                                </List.Item>
                            )
                        })}
                        </List>
                    </Segment><p/>
                </>
            }
		</Container>
	);
}

export default Page1;
