import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from '../pages/Home';
//import NotFound from '../pages/NotFound';
import { useLocation } from 'react-router';
import Page1 from '../pages/Page1';
import Terminal from '../pages/Terminal'
import SortirGabahText from '../pages/SortirGabahText';
import Sortir from '../pages/Sortir'
import SortirGabah from '../pages/SortirGabah';
import SortirBeras from '../pages/SortirBeras';

const ProtectedRoutes = props => {
      const location = useLocation()
      
      return (
            <Switch>
                  <Route exact path='/' key={location.key} component={Home} />
                  <Route exact path='/home' key={location.key} component={Home} />
                  <Route exact path='/gabah' key={location.key} component={SortirGabah} />
                  <Route exact path='/gabahtext' key={location.key} component={SortirGabahText} />
                  <Route exact path='/beras' key={location.key} component={SortirBeras} />
                  <Route exact path='/terminal/:screenstate' key={location.key} component={Terminal} />
                  <Route exact path='/link1/:par1' key={location.key} component={Page1} />
                  <Route component={Home} />
            </Switch>
      );

}

export default ProtectedRoutes

/*
 key={location.key}
 */