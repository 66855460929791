import React, { useEffect, useState, useRef } from 'react';
import { Container, Card, Header, Grid } from 'semantic-ui-react';
import Cookies from 'js-cookie';
import useGetData from '../services/useGetData';
import MessageBox from '../components/MessageBox';
import LoadingBox from '../components/LoadingBox';
import URLLocation from '../services/URLLocation';
import useAuthContext from '../services/stores/useAuthContext';
import useAdminContext from '../services/stores/useAdminContext';
import { useHistory } from 'react-router-dom';
import gabah from './gabah.png'
import beras from './beras.png'
import terminalicon from './terminalicon.png'


const Home = props => {
	const history = useHistory()
	const isMounted = useRef(false)
	const { stateContext } = useAuthContext();
	const { dispatchAdmin } = useAdminContext()
	const { stateGet, setGetUrl } = useGetData(null)
	const [ openError, setOpenError ] = useState(false)
	const [ isAdmin, setIsAdmin ] = useState(false)
	const [ needSetTerminal, setNeedSetTerminal ] = useState(false)
	
	useEffect(()=>{
		if (isMounted.current===true) 
		{
			if (stateGet.status==='SUCCESS') {
				setIsAdmin((stateGet.data.isadmin===1) ? true : false)
				dispatchAdmin({
					type: "LOGIN",
                	payload: stateGet.data
				})
			}
			else {
				setOpenError(true)
			}
		}
	},[stateGet.afterfetch])

	useEffect(()=>{
		const content = Cookies.get('bpr-terminalinfo')
		if (content===null || content===undefined) {
			setNeedSetTerminal(true)
		}
		setGetUrl(URLLocation.getUrl() + '/apiturus/v1/isadmin/' + stateContext.id)
		isMounted.current = true
	},[])

	const cardClick1 = () => {
		history.replace('/terminal/0')
	}

	const cardClick2 = () => {
		history.replace('/gabahtext')
	}

	const cardClick3 = () => {
		history.replace('/gabah')
	}

	const cardClick4 = () => {
		history.replace('/terminal/1')
	}
	
	return (
		<Container style={{paddingLeft:"5em"}}>
			<LoadingBox open={stateGet.isLoading}/>
			<MessageBox open={openError} message={'Error mendapatkan data'} onClose={()=>setOpenError(false)}/>
			<Header as='h1'>
				Aplikasi Sortir Gabah dan Beras 
				<Header sub>Sortir kualitas gabah dan beras saat proses pembelian bahan baku</Header>
			</Header>
			
			<p/>&nbsp;
			{(needSetTerminal===true) ?
				<>
					{(isAdmin===true) ?
						<>
							<Card onClick={cardClick1}>
								<Card.Content>
									<Card.Header>Setting Terminal</Card.Header>
									<Card.Meta>
										<span className='date'>Klik di sini untuk setting terminal</span>
									</Card.Meta>
									<Card.Description>
										Terminal ini belum diset. Anda perlu melakukan setting terminal untuk pertama kali sebelum memulai proses sortir gabah/beras 
									</Card.Description>
								</Card.Content>
							</Card>
						</>
						:
						<>
							<Card onClick={cardClick1}>
								<Card.Content>
									<Card.Header>Setting Terminal</Card.Header>
									<Card.Description>
										Terminal ini belum diset. Hubungi admin untuk setting terminal ini sebelum bisa melakukan sortir gabah/beras
									</Card.Description>
								</Card.Content>
							</Card>
						</>
					}
				</>
				:
				<>
					<Container>
						<Grid>
							<Grid.Row columns={2}>
								<Grid.Column>
									<Card onClick={cardClick2}>
										<Card.Content>
											<div align='center'>
												<img
													floated='left'
													size='tiny'
													src={gabah}
													height={75}
												/>
											</div>
											&nbsp;<p/>
											<Card.Header>Sortir Gabah/Beras : <b>INPUT</b></Card.Header>
											<Card.Meta>Klik di sini untuk memulai sortir gabah/beras</Card.Meta>
										</Card.Content>
									</Card>
								</Grid.Column>
							</Grid.Row>
							<>
							{(isAdmin===true) ?
								<Grid.Row>
									<Grid.Column>
										<Card onClick={cardClick4}>
											<Card.Content>
												<div align='center'>
												<img
													floated='left'
													size='tiny'
													src={terminalicon}
													height={75}
												/>
												</div>
												&nbsp;<p/>
												<Card.Header>Setting Terminal</Card.Header>
												<Card.Meta>Klik di sini untuk setting terminal</Card.Meta>
											</Card.Content>
										</Card>
									</Grid.Column>
								</Grid.Row>
								:
								null
							}
							</>
						</Grid>
					</Container>
				</>
			}
		</Container>
	);
}

export default Home;

/*
<Grid.Column> 
									<Card onClick={cardClick3}>
										<Card.Content>
											<div align='center'>
												<img
													size='tiny'
													src={beras}
													height={75}
												/>
											</div>
											&nbsp;<p/>
											<Card.Header>Sortir Beras</Card.Header>
											<Card.Meta>Klik di sini untuk memulai sortir beras</Card.Meta>
										</Card.Content>
									</Card>
								</Grid.Column>

								<Grid.Column>
									<Card onClick={cardClick3}>
										<Card.Content>
											<div align='center'>
												<img
													floated='left'
													size='tiny'
													src={beras}
													height={75}
												/>
											</div>
											&nbsp;<p/>
											<Card.Header>Sortir Gabah/Beras : <b>COUNTER</b></Card.Header>
											<Card.Meta>Klik di sini untuk memulai sortir gabah/beras</Card.Meta>
										</Card.Content>
									</Card>
								</Grid.Column>
								*/