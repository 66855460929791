import { useState, useEffect, useReducer } from 'react'
import Helper from './Helper';
import Cookies from 'js-cookie';


const dataFetchReducer = (statePost, action) => {
    switch (action.action.type) {
      case 'FETCH_INIT':
        return {
          ...statePost,
          isLoading: true,
          hasError: false
        };
      case 'FETCH_SUCCESS':
        return {
          ...statePost,
          isLoading: false,
          hasError: false,
          data: action.action.data,
          status : action.action.status,
          afterfetch : action.action.afterfetch
        };
      case 'FETCH_FAILURE':
        return {
          ...statePost,
          isLoading: false,
          hasError: true,
          errorMessage : action.action.errorMessage,
          status : action.action.status,
          afterfetch : action.action.afterfetch
        };
      default:
        throw new Error();
    }
};

const usePostData = (initialUrl, initialPayload, isCsrf,withHeaders) => {
    const [url, setPostUrl] = useState(initialUrl)
    const [ refresh, setRefresh ] = useState(null)
    const [payload, setPayload] = useState(initialPayload)
    const [statePost, dispatch] = useReducer(dataFetchReducer, {
        isLoading: false,
        hasError: false,
        data: null,
        errorMessage : '',
        status : 0,
        afterfetch : 0,
    });

    const setRefreshPost = () => {
      setRefresh(Helper.makeid())
    }
    
    useEffect(() => {   
        const csrftoken = Cookies.get('csrftoken')
        const postData = () => {
            var action = 
            { 
              type : 'FETCH_INIT',
              errorMessage : '',
              status : 0,
              afterfetch : 0,
              data : null
            }
            dispatch({action});
            var pload = {}
            var finalpayload = payload;
            if (withHeaders===true || withHeaders===null || withHeaders===undefined) {
              pload = {
                  method: "POST",
                  headers : { 'Content-Type' : 'application/json' },
                  body: JSON.stringify(finalpayload),
              }
            }
            else {
              pload = {
                method: "POST",
                body: JSON.stringify(finalpayload),
              }
            }

            if (isCsrf===true || isCsrf===null || isCsrf===undefined) {
              if (csrftoken!==null) {
                  pload = {
                    method: "POST",
                    headers: {
                        'Content-Type' : 'application/json',
                        'X-CSRF-Token' : csrftoken,
                    },
                    body: JSON.stringify(finalpayload),
                  }
              }
            }
            return fetch(url, pload)
            .then(r =>  r.json().then((data) => {
                if (r.status!==200 && r.status!==201 && r.status!==304) {
                    action = 
                    { 
                      type : 'FETCH_FAILURE',
                      errorMessage : 'Error',
                      status : 'FAILED',
                      afterfetch : Helper.makeid(),
                      data : null,
                    }
                    dispatch({ action });
                }
                else {
                    action = 
                    { 
                      type: 'FETCH_SUCCESS', 
                      data: data, 
                      status : 'SUCCESS',
                      afterfetch : Helper.makeid(),
                      errorMessage : '',
                    }
                    dispatch({action});
                }
            }))
            .catch((error)=>{
                var action = 
                { 
                  type: 'FETCH_FAILURE', 
                  errorMessage : error.message, 
                  status : 'FAILED',
                  afterfetch : Helper.makeid(),
                  data : null
                }
                dispatch({action});
            })
        }
        if ((url!==null && payload!==null) || refresh!==null)
        postData()
    }, [url, payload, refresh])
    
    return { statePost, setPostUrl, setPayload, setRefreshPost }
}

export default usePostData