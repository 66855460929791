import { useState, useEffect, useReducer } from 'react'
import Helper from './Helper';
import Cookies from 'js-cookie';

const dataFetchReducer =(stateGetParallel, action) => {
    switch (action.action.type) {
      case 'FETCH_INIT':
        return {
          ...stateGetParallel,
          isLoading: true,
          hasError: false
        };
      case 'FETCH_SUCCESS':
        return {
          ...stateGetParallel,
          isLoading: false,
          hasError: false,
          data: action.action.data,
          status : action.action.status,
          afterfetch : action.action.afterfetch,
        };
      case 'FETCH_FAILURE':
        return {
          ...stateGetParallel,
          isLoading: false,
          hasError: true,
          errorMessage : action.action.errorMessage,
          status : action.action.status,
          afterfetch : action.action.afterfetch,
        };
      default:
        throw new Error();
    }
};

const useParallelGetData = (initialUrls, isCsrf, withHeaders) => {
    const [urls, setGetUrls] = useState(initialUrls)
    const [refresh, setRefresh] = useState(null)
    const [stateGetParallel, dispatch] = useReducer(dataFetchReducer, {
        isLoading: false,
        hasError: false,
        data: null,
        errorMessage : '',
        status : 0,
        afterfetch : 0,
    });

    const setRefreshParallelGet = () => {
      setRefresh(Helper.makeid())
    }

    useEffect(() => {   
        const csrftoken = Cookies.get('csrftoken')
        const fetchData = async () => {
            try {
                var action = 
                { 
                    type : 'FETCH_INIT',
                    errorMessage : '',
                    status : 0,
                    data : null,
                    afterfetch : 0
                }
                dispatch({action});
                var pload = {}
                if (withHeaders===true || withHeaders===null || withHeaders===undefined) {
                  pload = {
                      method: "GET",
                      headers : { 'Content-Type' : 'application/json' }
                  }
                }
                else {
                  pload = {
                    method: "GET",
                  }
                }
                if (isCsrf===true || isCsrf===null || isCsrf===undefined) {
                  if (csrftoken!==null) {
                      pload =  {
                          method: "GET",
                          headers: {
                              'Content-Type' : 'application/json',
                              'X-CSRF-Token' : csrftoken
                          },
                          
                      }
                  }
                }
                var data =  await Promise.all(
                            urls.map(
                                    url =>
                                        fetch(url,pload)
                                        .then(
                                            (response) => response.json()
                                        )
                                    )
                            );
                action = 
                { 
                    type: 'FETCH_SUCCESS', 
                    data: data, 
                    status : 'SUCCESS', 
                    afterfetch : Helper.makeid(),
                    errorMessage : '',
                };
                dispatch({action});
                return;
            }
            catch(error) {
                var action = 
                { 
                  type: 'FETCH_FAILURE', 
                  errorMessage : error.message, 
                  status : 'FAILED',
                  afterfetch : Helper.makeid(),
                  data : null
                }
                dispatch({action});
            }
        }
        if (urls!==null && urls!=='' && urls.length>0) {
          if (refresh!==null || stateGetParallel.data===null) fetchData()
        }
    }, [urls, refresh])
    return { stateGetParallel, setGetUrls, setRefreshParallelGet }
}

export default useParallelGetData