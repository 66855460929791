import React, { useEffect, useState } from 'react';
import {
    Header,
    Icon,
    Container,
    Grid,
    Button,
    Label,
    List,Form
} from 'semantic-ui-react';
import LoadingBox from '../components/LoadingBox';
import useParallelGetData from '../services/useParallelGetData';
import usePostData from '../services/usePostData';
import URLLocation from '../services/URLLocation';
import ConfirmationBox from '../components/ConfirmationBox';
import ConfirmationBox2 from '../components/ConfirmationBox';
import ModalSortirGabah from './Modals/ModalSortirGabah';
import Helper from '../services/Helper';
import MessageBox from '../components/MessageBox';
import gabah from './gabah.png'
import useAuthContext from '../services/stores/useAuthContext';
import Cookies from 'js-cookie';
import EmptySpace from '../components/EmptySpace'

const SortirGabah = props => {
    const { stateGetParallel, setGetUrls, setRefreshParallelGet } = useParallelGetData(null)
    const { statePost, setRefreshPost, setPayload } = usePostData(URLLocation.getUrl() + '/apiturus/v1/saveturus',null)
    const [ filtered, setFiltered ] = useState(false)
    const [ openConfirm, setOpenConfirm ] = useState(false)
    const [ openConfirm2, setOpenConfirm2 ] = useState(false)
    const [ lblFilter, setLblFilter ] = useState(null)
    const [ lblNota, setLblNota ] = useState(null)
    const [ lblJam, setLblJam ] = useState(null)
    const [ openModal, setOpenModal ] = useState(false)
    const [ dataSource1, setDataSource1 ] = useState(null)
    const [ dataSource2, setDataSource2 ] = useState(null)
    const [ zakQty, setZakQty ] = useState(0)
    const [ message, setMessage ] = useState(null)
    const [ openError, setOpenError ] = useState(false)
    const [ qtyTotal, setQtyTotal ] = useState(0)
    const [ totalBerat, setTotalBerat ] = useState(0)
    const [ beratZak, setBeratZak ] = useState(0)
    const [ params, setParams ] = useState(null)
    const { stateContext } = useAuthContext()
    const [ productparent, setProductparent ] = useState(null)
    const [ zakTolak, setZakTolak ] = useState(0)

    const filterClick = () => {
        setOpenModal(true)
    }

    const handleConfirm = () => {
        setFiltered(false)
        setDataSource1(null)
        setDataSource2(null)
        setLblFilter(null)
        setLblNota(null)
        setLblJam(null)
        setOpenConfirm(false)
    }

    const handleConfirm2 = () => {
        setOpenConfirm2(false)
        const dt = JSON.parse(Cookies.get('bpr-terminalinfo'))
        var details = []
        var total = 0
        for(var i=0;i<dataSource2.length;i++) {
            details.push({
                productid : dataSource1[i].id,
                productname : dataSource1[i].name,
                turusqty : dataSource2[i].jmlzak,
                beratperzak : dataSource2[i].beratperzak,
                productparent : dataSource1[i].productparent
            })
            total+=dataSource2[i].jmlzak*dataSource2[i].beratperzak
        }
        details.sort((a, b) => parseFloat(b.turusqty*b.beratperzak) - parseFloat(a.turusqty*a.beratperzak));
        const selisih = total - params.isitotal
        setPayload(
            {
                trxdate : params.datetrx,
                zaktolak : zakTolak,
                trxend : new Date(),
                gudang : params.gudang,
                rombongan : params.rombongan,
                isitotal : params.isitotal,
                qty : params.qty,
                berat : params.berat,
                supplierid : params.supplier,
                suppliername : params.suppliertext,
                trukno : params.nokend,
                truktype : params.jenistruk,
                status : 1,
                antrianno : params.nota,
                details : details,
                username : stateContext.id,
                terminalcode : dt.terminal,
                sitename : dt.site,
                productid : '',
                productname : '',
                nota : params.notatext,
                selisih : selisih
                
            }
        )
        setRefreshPost()
    }

    const sortData = (arr, asc) => {
        if (asc===true) {
            var ret = arr.sort((a, b) => {
                if (a.name < b.name) {
                return -1;
                }
            });
            return ret
        }
        else {
            var ret = arr.sort((a, b) => {
                if (a.name > b.name) {
                return -1;
                }
            });
            return ret
        }
    }

    useEffect(()=>{
        if (stateGetParallel.status==='SUCCESS') {
            var arr1 = []
            for (var t=0;t<stateGetParallel.data.length;t++) {
                for(var i=0;i<stateGetParallel.data[t].length;i++) {
                    arr1.push({
                        id : stateGetParallel.data[t][i].id,
                        name : stateGetParallel.data[t][i].name,
                        productparent : productparent[t]
                    })
                }
            }
            setDataSource1(sortData(arr1,true))
            var arr2 = []
            for (var t=0;t<stateGetParallel.data.length;t++) {
                for(var i=0;i<stateGetParallel.data[t].length;i++) {
                    arr2.push({ 
                        jmlzak : 0,
                        beratperzak : params.berat
                    })
                }
            }
            setDataSource2(arr2)
        }
        else {
            if (stateGetParallel.status==='FAILED') {
                setOpenError(true)
                setMessage('Error mengambil data')
            }
        }
    },[stateGetParallel.afterfetch])

    useEffect(()=>{
        if (statePost.status==='SUCCESS') {
            setFiltered(false)
            setDataSource1(null)
            setDataSource2(null)
            setLblFilter(null)
            setLblNota(null)
            setLblJam(null)
        }
        else {
            if (statePost.status==='FAILED') {
                setOpenError(true)
                setMessage('Error menyimpan data')
            }
        }
    },[statePost.afterfetch])

    const refreshData = (pars) => {
        setParams(pars)
        setFiltered(true)
        setOpenModal(false)
        var strberat = ''
        if (pars.berat>0) 
            strberat = ' - (Satuan ' + pars.berat + 'kg, Netto : ' + Helper.formatNumber(pars.isitotal) + ' kg)'
        else
            strberat = ' - (Satuan PROPORSIONAL OLEH SISTEM)'
        const str = pars.suppliertext + ', ' + pars.nokend + 
        ',      Produk : ' + pars.jenisgabahtext + strberat
        setLblFilter(str)
        setLblNota('NOTA : ' + pars.notatext)
        setLblJam('JAM : ' + Helper.formatDate(pars.jammulai))
        setTotalBerat(pars.qty)
        setQtyTotal(0)
        setBeratZak(0)
        setZakQty(0)
        var urls = []
        var prods = []
        for (var i=0;i<pars.jenisgabah.length;i++) {
            urls.push(
                URLLocation.getUrl() + '/apiturus/v1/getsubitems/' + pars.jenisgabah[i]
            )
            prods.push(pars.jenisgabah[i])
        }
        setProductparent(prods)
        setGetUrls(urls)
        setRefreshParallelGet()
    }

    const clearFilterClick = () => {
        setOpenConfirm(true)
    }

    const saveClick = () => {
        if (qtyTotal<=0) {
            setMessage('Zak tidak boleh nol')
            setOpenError(true)
        }
        else
            setOpenConfirm2(true)
    }

    /*
        const addItem = (index) => {
            var arr = dataSource2.slice()
            var ttl = parseFloat(totalBerat) + parseFloat((3*params.berat))
            //if (parseFloat(qtyTotal*params.berat)<ttl) {
                arr[index] = arr[index] + 1
                var qty = qtyTotal
                qty = qty + 1
                setBeratZak(qty*params.berat)
                setQtyTotal(qty)
            //}
            setDataSource2(arr)
        }

        const removeItem = (index) => {
            var arr = dataSource2.slice()
            if (arr[index]>0) {
                arr[index] = arr[index] - 1
                var qty = qtyTotal
                qty = qty - 1
                setQtyTotal(qty)
            }
            setDataSource2(arr)
        }
    */

    const onChange1 = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value !== '' && re.test(e.target.value)) {
            var idx = e.target.id.substring(3)//,e.target.id.length-3)
            var dt = dataSource2.slice()
            dt[idx].jmlzak = parseFloat(e.target.value)

            var qty = 0
            var qty2 = 0
            for(var i=0;i<dt.length;i++) {
                qty2 = qty2 + dt[i].jmlzak
                qty = qty + (dt[i].jmlzak * dt[i].beratperzak)
            }
            //if (qty*params.berat>params.isitotal) {
            //    setMessage('Berat melebihi berat timbangan')
            //    setOpenError(true)
            //}
            //else {

                //setBeratZak(qty*params.berat)
                setZakQty(qty2)
                setBeratZak(qty)
                setQtyTotal(qty)
                setDataSource2(dt)
            //}
        }
	}

    function isFloat(value) {
        if (
          typeof value === 'number' &&
          !Number.isNaN(value) &&
          !Number.isInteger(value)
        ) {
          return true;
        }
      
        return false;
      }

    const onChange2 = (e) => {
        const re = /^[0-9\b]+$/
        /*/^\d+(\.\d{1,2})?$/     */
        if (e.target.value !== '') { //|| re.test(e.target.value)) {
            var idx = e.target.id.substring(3)//,e.target.id.length-3)
            var dt = dataSource2.slice()
            if (String(e.target.value).endsWith('.')) {
                dt[idx].beratperzak = e.target.value
                setDataSource2(dt)
                return
            }
            dt[idx].beratperzak = parseFloat(e.target.value)

            var qty = 0
            var qty2 = 0
            for(var i=0;i<dt.length;i++) {
                qty2 = qty2 + dt[i].jmlzak
                qty = qty + (dt[i].jmlzak * dt[i].beratperzak)
            }
            //if (qty*params.berat>params.isitotal) {
            //    setMessage('Berat melebihi berat timbangan')
            //    setOpenError(true)
            //}
            //else {

                //setBeratZak(qty*params.berat)
                setZakQty(qty2)
                setBeratZak(qty)
                setQtyTotal(qty)
                setDataSource2(dt)
            //}
        }
	}

    const onChangeZakTolak = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value !== '' && re.test(e.target.value)) {
            setZakTolak(e.target.value)
        }
    }

    return (
        <>
            {(stateGetParallel.isLoading || statePost.isLoading) ?
                <LoadingBox />
                :
                <Container style={{ paddingLeft: '1em'}}>
                    <MessageBox open={openError} message={message} onClose={()=>setOpenError(false)}/>
                    <ModalSortirGabah filtered={filtered} parfilters={params} open={openModal} refreshData={refreshData} onClose={()=>setOpenModal(false)}/>
                    <ConfirmationBox open={openConfirm} message='Hapus data?' onYes={handleConfirm} onNo={()=>setOpenConfirm(false)}/>
                    <ConfirmationBox2 open={openConfirm2} message='Simpan data?' onYes={handleConfirm2} onNo={()=>setOpenConfirm2(false)}/>
                    <Grid padded verticalAlign='middle'>
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <Header as='h2'>
                                    <img
                                        floated='left'
                                        src={gabah}
                                    />
                                    Sortir Gabah/Beras
                                </Header>
                            </Grid.Column>
                            <Grid.Column textAlign='right'>
                                {(filtered) ?
                                    <Label size='large'>{lblJam}</Label>
                                    :
                                    null
                                }
                            </Grid.Column>
                        </Grid.Row>
                        {(filtered) ?
                            <>
                                <Grid.Row>
                                    <Grid.Column width={10}>
                                        <Label color='orange' as='a' onClick={()=>setOpenModal(true)} size='large'>
                                            {lblNota} 
                                        </Label><br/>
                                        <Label size='large'>
                                            {lblFilter} 
                                        </Label>
                                    </Grid.Column>
                                    <Grid.Column textAlign='right' width={3}>
                                        <Label circular color='blue' size='huge'>{Helper.formatNumber(zakQty)} zak</Label>
                                    </Grid.Column>
                                    <Grid.Column width={3}>
                                        <Label circular color='blue' size='huge'>{Helper.formatNumber(beratZak)} kg</Label>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns={2}>
                                    <Grid.Column>
                                        <Button icon labelPosition='left' onClick={clearFilterClick}>
                                            <Icon name='close'/>
                                            Clear
                                        </Button>
                                    </Grid.Column>
                                    <Grid.Column textAlign='right'>
                                        <Button primary icon labelPosition='left' onClick={saveClick}>
                                            <Icon name='check'/>
                                            Simpan
                                        </Button>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row textAlign='right'>
                                    <Grid.Column>
                                        <label>Jumlah zak ditolak</label>
                                        <Form>
                                            <Form.Input
                                                textAlign='right'
                                                id={'zaktolak'}
                                                name={'zaktolak'}
                                                value={zakTolak}
                                                onChange={onChangeZakTolak}
                                            />
                                        </Form>          
                                    </Grid.Column>
                                </Grid.Row>
                            </>
                            :
                            <>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Button onClick={filterClick}>Pilih nota timbang</Button>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column textAlign='center'>
                                        <EmptySpace
                                              title='Tidak ada data'
                                              caption='Tidak ada data. Pilih nota timbang dahulu'
                                              image={null}
                                              buttonCaption={null}
                                              showButton={false}
                                              link={null}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </>
                        }
                        {(dataSource1) ?
                            <Grid.Row>
                                <Grid.Column>
                                    <List divided>
                                        <List.Item>
                                            <List.Content floated='right'>
                                                <div align='center'>
                                                    <b><Label color='orange'>
                                                        JUMLAH ZAK
                                                    </Label>
                                                    </b>
                                                </div>              
                                                &nbsp;&nbsp;&nbsp;
                                            </List.Content>
                                            <List.Content floated='right'>
                                                <div align='center'>
                                                    <b><Label color='orange'>
                                                        BERAT PER ZAK (KG)
                                                    </Label>
                                                    </b>
                                                </div>                                    
                                                &nbsp;&nbsp;&nbsp;
                                            </List.Content>
                                            <List.Content>
                                                <b><Label color='orange'>
                                                    JENIS KUALITAS
                                                </Label>
                                                </b>
                                            </List.Content>
                                        </List.Item>
                                    </List>
                                </Grid.Column>
                            </Grid.Row>
                            :
                            null
                        }
                        <Grid.Row>
                            <Grid.Column>
                                <List divided>
                                    {dataSource1 &&
                                        dataSource1.map((obj1,index) =>{
                                            return( 
                                                <List.Item>
                                                    <List.Content floated='right'>
                                                        <Form>
                                                            <Form.Input
                                                                textAlign='right'
                                                                id={"qty" + String(index)}
                                                                name={"qty" + String(index)}
                                                                value={dataSource2[index].jmlzak}
                                                                onChange={onChange1}
                                                            />
                                                        </Form>                                                      
                                                    </List.Content>
                                                    <List.Content floated='right'>
                                                        <Form>
                                                            <Form.Input
                                                                textAlign='right'
                                                                id={"zak" + String(index)}
                                                                name={"zak" + String(index)}
                                                                value={dataSource2[index].beratperzak}
                                                                onChange={onChange2}
                                                            />
                                                        </Form>                                                      
                                                    </List.Content>
                                                    <List.Icon name='box' size='large' verticalAlign='middle' />
                                                    <List.Content>
                                                        {obj1.name}
                                                    </List.Content>
                                                </List.Item>
                                            )
                                        })}
                                </List>
                            </Grid.Column>
                        </Grid.Row>           
                    </Grid>
                </Container>
            }
        </>
    )
}

export default SortirGabah

/*
&nbsp;&nbsp;&nbsp;
 <Grid.Column textAlign='right'>
                                        <Label circular color='blue' size='huge'>{qtyTotal} ZAK</Label>
                                        <Label circular color='blue' size='huge'>{beratZak} kg</Label>
                                    </Grid.Column>

                                    */