import React from 'react';
import { Dimmer, Loader, Modal } from 'semantic-ui-react';

const LoadingBox = props => {
    return (
        <Modal size='mini' open={(props.open!==null && props.open!==undefined) ? props.open : true}>
            <Modal.Header>Please wait...</Modal.Header>
            <Modal.Content></Modal.Content>
            <Dimmer active inverted>
                <Loader inverted content='Loading'/>
            </Dimmer>
        </Modal>
    );
}

export default LoadingBox;
