import React, { useState, useRef, useEffect } from 'react';
import URLLocation from '../services/URLLocation';
import { Container, Header, Grid, Dropdown, Button } from 'semantic-ui-react';
import useGetData from '../services/useGetData';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import Cookies from 'js-cookie'
import usePostData from '../services/usePostData';


const Terminal = props => {
    const [ screenState, setScreenState ] = useState((props.match.params.screenstate) ? parseInt(props.match.params.screenstate) : 0)
    const isMounted = useRef(false)
    const { stateGet, setGetUrl, setRefreshGet } = useGetData(null)
    const { statePost, setPayload, setPostUrl, setRefreshPost } = usePostData(null,null)
    const [ openError, setOpenError ] = useState(false)
    const [ sites, setSites ] = useState(null)
    const [ selectedSite, setSelectedSite ] = useState(null)
    const [ selectedSiteName, setSelectedSiteName ] = useState(null)
    const [ terminals, setTerminals ] = useState(null)
    const [ selectedTerminal, setSelectedTerminal ] = useState(null)
    const [ mode, setMode] = useState(1)
    const [ message, setMessage ] = useState(null)
    
    
    useEffect(()=>{
        if (isMounted.current===true) {
            if (stateGet.status==='SUCCESS') {
                if (mode===1) {
                    var data = []
                    for(var i=0;i<stateGet.data.length;i++) {
                        data.push({
                            text : stateGet.data[i].sitename,
                            value : stateGet.data[i].kode_idempiere
                        })
                    }
                    setSites(data)
                }
                else {
                    var data = []
                    for(var i=0;i<stateGet.data.length;i++) {
                        data.push({
                            text: stateGet.data[i].terminalcode + ' - ' + stateGet.data[i].location_description,
                            value : stateGet.data[i].terminalcode
                        })
                    }
                    setTerminals(data)
                }
            }
            else {
                if (stateGet.status==='FAILED') {
                    setMessage('Error mendapatkan data')
                    setOpenError(true)
                }
            }
        }
    },[stateGet.afterfetch])

    useEffect(()=>{
        if (isMounted.current===true) {
            if (statePost.status==='SUCCESS') {
                setMessage('Data berhasil disimpan')
                setOpenError(true)
                if (screenState===0) 
                    setScreenState(1)
                else
                    setScreenState(0)
            }
            else {
                if (statePost.status==='FAILED') {
                    setMessage('Error menyimpan data')
                    setOpenError(true)
                }
            }
        }
    },[statePost.afterfetch])

    useEffect(()=>{
        setGetUrl(URLLocation.getUrl() + '/apiturus/v1/getsites')
        if (screenState===1) {
            const dt = JSON.parse(Cookies.get('bpr-terminalinfo'))
            setSelectedSiteName(dt.sitename)
            setSelectedSite(dt.site)
            setSelectedTerminal(dt.terminal)
        }
        isMounted.current = true
    },[])
	
    const dropdownChange1 = (event, {value}) => {
        var idx = findName(value)
        var site = sites[idx].text
        setSelectedSiteName(site)
        setSelectedSite(value)
        setSelectedTerminal(null)
        setMode(2)
        setGetUrl(URLLocation.getUrl() + '/apiturus/v1/getavailableterminals/' + site)
        setRefreshGet()
    }

    const findName = (val) =>{
        var found = false
        var i=0;
        var idx = 0
        while(found===false && i<sites.length) {
            if (sites[i].value===val) {
                found = true
                idx = i
            }
            i++
        }
        return idx
    }

    const dropdownChange2 = (event, { value }) => {
        setSelectedTerminal(value)
    }

    const buttonClick = () => {
        if (screenState===0) {
            if (selectedSite === null || selectedTerminal === null) {
                setMessage('Lokasi dan terminal harus dipilih')
                setOpenError(true)
            }
            else {
                setPayload(null)
                const data = {
                    sitename : selectedSiteName,
                    site : selectedSite,
                    terminal : selectedTerminal
                }
                Cookies.set('bpr-terminalinfo', JSON.stringify(data),{ expires: 365 })
                setPostUrl(URLLocation.getUrl() + '/apiturus/v1/setsiteandterminal')
                setPayload({
                    sitename : selectedSiteName, 
                    terminalcode : selectedTerminal
                })
                setRefreshPost()
            }         
        }
        else {
            setPayload(null)
            setPostUrl(URLLocation.getUrl() + '/apiturus/v1/clearsiteandterminal')
            const dt = JSON.parse(Cookies.get('bpr-terminalinfo'))
            Cookies.remove('bpr-terminalinfo')
            setPayload({
                sitename : dt.sitename, 
                terminalcode : dt.terminal
            })
            setRefreshPost()
        }
    }
	
	return (
		<Container style={{paddingLeft:"5em"}}>
            <LoadingBox open={stateGet.isLoading || statePost.isLoading}/>
            <MessageBox open={openError} message={message} onClose={()=>setOpenError(false)}/>
            <Header as='h1'>
                Setting Terminal
                <Header.Subheader>
                    Setting terminal untuk sortir gabah/padi
                </Header.Subheader>
            </Header>
            &nbsp;<p/>
            <Grid>
                {(screenState===0) ?
                <>
                    <Grid.Row>
                        <Grid.Column width={4}/>
                        <Grid.Column width={8}>
                            <label>Pilih Lokasi</label>
                            <Dropdown
                                placeholder='Pilih Lokasi'
                                selection
                                fluid
                                value={selectedSite}
                                onChange={dropdownChange1}
                                options={sites}
                            />
                        </Grid.Column>
                        <Grid.Column width={4}/>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={4} />
                        <Grid.Column width={8}>
                            <label>Pilih Terminal</label>
                            <Dropdown
                                placeholder='Pilih Terminal'
                                selection
                                fluid
                                value={selectedTerminal}
                                onChange={dropdownChange2}
                                options={terminals}
                            />
                        </Grid.Column>
                        <Grid.Column width={4} />
                    </Grid.Row>
                </>
                :
                <>
                    <Grid.Row>
                        <Grid.Column width={4}/>
                        <Grid.Column width={8}>
                            <label>Lokasi : {selectedSiteName}</label>
                        </Grid.Column>
                        <Grid.Column width={4}/>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={4} />
                        <Grid.Column width={8}>
                            <label>Terminal : {selectedTerminal}</label>
                        </Grid.Column>
                        <Grid.Column width={4} />
                    </Grid.Row>
                </>
                }
                <Grid.Row>
                    <Grid.Column width={4} />
                    <Grid.Column width={8}>
                        <Button primary onClick={buttonClick}>{(screenState===0) ? 'Set Terminal' : 'Clear Terminal'}</Button>
                    </Grid.Column>
                    <Grid.Column width={4} />
                </Grid.Row>
            </Grid>
        </Container>
	);
}

export default Terminal;
