import React, { useState} from 'react';

import {
  Menu,
  Container,
  Icon,
  Image,
  Dropdown,
  Segment,
  List
} from 'semantic-ui-react';
import ConfirmationBox from './ConfirmationBox';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import useAuthContext from '../services/stores/useAuthContext';
import { createMedia } from "@artsy/fresnel"
import HeaderMenu from './HeaderMenu'
//import FooterMenu from './FooterMenu'
import UnprotectedRoutes from './UnprotectedRoutes';
import Sidebar from "react-sidebar";
import logoBPR from './logoBPR3.png'

const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    sm: 0,
    md: 768,
    lg: 1024,
    xl: 1192,
  },
})

const headerlinks = [ 
    { link : '/settings'},
    { link : '/profile'},
    { link : '/help'},
    { link : '/home'},
]

const NavBarMobile = props => {
    const history = useHistory();
    const [ open, setOpen ] = useState(false)
    const [ sidebarOpen, setSidebarOpen ] = useState(false)
    const [ docked, setDocked ] = useState(false)
    const { dispatch, stateContext } = useAuthContext()


    const { 
        children,
        sidebarmenus
    } = props


    const handleCancel = () => {
        setOpen(false)
    }

    const handleConfirm = () => {
        setOpen(false)
        dispatch({
            type: "LOGOUT",
            payload: null
        })
    }

    const signOutClick = () => 
    {
        setOpen(true)
    }

    const profileClick = () =>
    {
        history.push(headerlinks[1].link)
    }

    const helpClick = () => 
    {
        history.push(headerlinks[2].link)
    }

    const setSidebar = () => 
    {
        setDocked(!docked)
        setSidebarOpen(!sidebarOpen)
    }

    //menu item
        var help = null
        var settings = <b><Dropdown.Item as={Link} to={headerlinks[0].link} icon='setting' text='Settings'/></b>
       
    //group menu
        var group_general = 
            <Dropdown text='More'>
                <Dropdown.Menu>
                    {help}
                    {settings}
                </Dropdown.Menu>
            </Dropdown> 

    //menu container 
        var menu_container = 
            <Menu.Item as='a'>
                {group_general}
            </Menu.Item>

        var sidebarcontent = 
        <div>
            <Segment basic style={{ minHeight: '89vh', maxWidth:180,textAlign: 'left'}} color='black' inverted>
                <List verticalAlign='middle' selection inverted>
                    {(sidebarmenus).map((q) => 
                        (
                            <>
                                <List.Item>
                                    <List.Icon name={q.icon_id} size='large' verticalAlign='middle'/>
                                    <List.Content>
                                        <List.Header as={Link} to={'/' + q.link_id}>{q.menu_name}</List.Header>
                                    
                                    </List.Content>
                                </List.Item>
                            </>
                        )
                    )}
                </List>
            </Segment>
        </div>

    return (
        <>
            <ConfirmationBox message='Are you sure you want to quit the application ?' open={open} onYes={handleConfirm} onNo={handleCancel}/>
            <Sidebar
                sidebar={sidebarcontent}
                open={sidebarOpen}
                docked={docked}
                styles={{ sidebar: { marginTop: "4.5em" } }}
            >
                <Menu borderless size='small' fixed="top" pointing >
                    <Menu.Item onClick={setSidebar}>
                        <Icon name='sidebar'/>
                    </Menu.Item>
                    <Menu.Item as={Link} to={headerlinks[3].link} style={{ marginLeft: '2.5em' }}>
                        <Image size="mini" 
                            src={logoBPR}
                            //src="https://react.semantic-ui.com/logo.png"
                        />
                    </Menu.Item>
                    <Menu.Menu position="right">
                        {menu_container}
                        <Menu.Item>
                            <Dropdown 
                                //text={props.username} 
                                icon='user outline' 
                                className='icon'
                                floating
                                //labeled
                                button
                            >
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={profileClick}>Profile</Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item onClick={helpClick}>Help</Dropdown.Item>
                                    <Dropdown.Item onClick={signOutClick}>Sign Out</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Menu.Item>
                    </Menu.Menu>
                </Menu>
                <Container style={{marginTop: "5em"}}>{children}</Container>
            </Sidebar>
        </>
    );
}

const NavBarDesktop = props => {
    const history = useHistory();
    const [ open, setOpen ] = useState(false)
    const [ sidebarOpen, setSidebarOpen ] = useState(true)
    const [ docked, setDocked ] = useState(true)
    const { dispatch, stateContext } = useAuthContext()

    const { 
        children,
        sidebarmenus
    } = props

    const handleCancel = () => {
        setOpen(false)
    }

    const handleConfirm = () => {
        setOpen(false) 
        dispatch({
            type: "LOGOUT",
            payload: null
        })
    }

    const signOutClick = () => 
    {
        setOpen(true)
    }

    const setSidebar = () => 
    {
        setDocked(!docked)
        setSidebarOpen(!sidebarOpen)
    }

    const profileClick = () =>
    {
        history.push(headerlinks[1].link)
    }

    const helpClick = () => 
    {
        history.push(headerlinks[2].link)
    }


    var help = null
    var settings = 
        <Menu.Item fitted as={Link} to={headerlinks[0].link}>
            &nbsp;&nbsp;<Icon name='setting' size='large'/>&nbsp;&nbsp;
        </Menu.Item>

    var sidebarcontent = 
    <div>
        <Segment basic style={{ minHeight: '89vh', maxWidth:180,textAlign: 'left'}} color='black' inverted>
            <List verticalAlign='middle' selection inverted>
                {(sidebarmenus).map((q) => 
                    (
                        <>
                            <List.Item>
                                <List.Icon name={q.icon_id} size='large' verticalAlign='middle'/>
                                <List.Content>
                                    <List.Header as={Link} to={'/' + q.link_id}>{q.menu_name}</List.Header>
                                </List.Content>
                            </List.Item>
                        </>
                    )
                )}
            </List>
        </Segment>
    </div>

    return (
        <>
            <ConfirmationBox message='Are you sure you want to quit the application ?' open={open} onYes={handleConfirm} onNo={handleCancel}/>
            <Sidebar
                sidebar={sidebarcontent}
                open={sidebarOpen}
                docked={docked}
                styles={{ sidebar: { marginTop: "4.5em" } }}
            >
            <Menu borderless size='small' fixed="top" pointing>
                <Menu.Item onClick={setSidebar}>
                    <Icon name='sidebar'/>
                </Menu.Item>
                <Menu.Item as={Link} to={'/home'} style={{ marginLeft: '2.5em' }}>
                    <Image size="mini" 
                        src={logoBPR}
                        //src="https://react.semantic-ui.com/logo.png" 
                    />
                </Menu.Item>
                <Menu.Item as={Link} to={'/home'}>
                    Home
                </Menu.Item>
                <Menu.Menu position="right">
                    {help}
                    {settings}
                    <Menu.Item>
                        <Dropdown 
                            text={props.username}
                            floating
                            button
                            size='small'
                        >
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={profileClick}>Profile</Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item onClick={helpClick}>Help</Dropdown.Item>
                                <Dropdown.Item onClick={signOutClick}>Sign Out</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Menu.Item>
                </Menu.Menu>
            </Menu>
            <Container style={{ marginTop: "5em"}}>{children}</Container>
            </Sidebar>
        </>
    )
}

const NavBar = props => {
    const { children } = props

    return (
        <MediaContextProvider>
            <Media lessThan="lg">
                <NavBarMobile
                    username={props.username}
                    sidebarmenus={props.sidebarmenus}
                >  
                    {children}
                </NavBarMobile>
            </Media>
            <Media greaterThanOrEqual="lg">
                <NavBarDesktop
                    username={props.username}
                    sidebarmenus={props.sidebarmenus}
                >
                    {children}
                </NavBarDesktop>
            </Media>
        </MediaContextProvider>
      
    )
}

const AppBar = props => {
    const { children } = props
    const history = useHistory()
    const { stateContext } = useAuthContext()
    return (
        <>
            {(stateContext.isLogin===true) ?
                <NavBar   
                    sidebarmenus={props.sidebarmenus} 
                    username={props.username}
                >
                    {children}
                </NavBar>
                :
                <>
                    <HeaderMenu/>
                    <UnprotectedRoutes>
                        {history.replace('/logout')}
                    </UnprotectedRoutes>
                </>
            }
        </>
    )

}

AppBar.propTypes = {
  children: PropTypes.node,
}

export default AppBar;

