class menus {
    getDeliveryMenus() {
        const dt =  [ 
            {
                icon_id : 'home', 
                link_id : 'home', 
                menu_name : 'Home',
                id : 114,
                description : 'Halaman Utama'
            },
           
           
	    ]
		return dt;
    }

    getSalesMenus() {
        const dt =  [ 
            {
                icon_id : 'home', 
                link_id : 'home', 
                menu_name : 'Home',
                id : 114,
                description : 'Halaman Utama'
            },
           
           
	    ]
		return dt;
    }
}

const AppMenus = new menus();  
export default AppMenus;