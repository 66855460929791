import React, { useEffect,useState } from 'react';
import LoginProcess from './LoginProcess';
import ProtectedContainer from './ProtectedContainer';
import UnprotectedContainer from './UnprotectedContainer';
import useAuthContext from './services/stores/useAuthContext';

const AppInit = props => {
    const { dispatch } = useAuthContext();
    const [ condition, setCondition ] = useState(0)
    const { 
        code, 
        content,
    } = props

    useEffect(()=>{
        //alert(JSON.stringify(content))
        //if (content!==null && content!==undefined) {
        //    dispatch({
        //        type: "LOGIN",
        //        payload: JSON.parse(content)
        //    });
        //}
        setCondition(checkCondition())
    },[])

    const checkCondition = () => {
        
        var cond = 0;
        if (code!==null && code!=='' && code!==undefined) 
            cond = 1
        else {
            if (content!==null && content!=='' && content!==undefined) 
                cond = 2
            else {
                cond = 0
            }
        }      
        return cond
    }

    return (
        <>
            {condition===0 && <UnprotectedContainer/> }
            {condition===1 && <LoginProcess code={code}/>}
            {condition===2 && <ProtectedContainer/>} 
        </> 
    );
}

export default AppInit;

//{//condition===5 && <ViewPublicResume/>}
            