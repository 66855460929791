import React from 'react';
import { Modal, Button, Header } from 'semantic-ui-react';
//import {  Dimmer, Loader, Modal } from 'semantic-ui-react';

const MessageBox = props => {
    return (
        <Modal
        open={props.open}
        size='mini'
       
      >
        <Modal.Content>
          <Modal.Description>
            <Header>Info</Header>
            <p>
              {props.message}
            </p>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            content="OK"
            labelPosition='right'
            icon='checkmark'
            onClick={props.onClose}
            positive
          />
        </Modal.Actions>
      </Modal>
       
    );    
}

export default MessageBox


/*
  <Modal size={'mini'} open={props.open}>
            <Modal.Header>
                <Modal.Title>Info</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{props.message}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={props.onClose}>OK</Button>
            </Modal.Footer>
        </Modal>
*/