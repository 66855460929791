import { useState, useEffect, useReducer } from 'react'
import Helper from './Helper';
import Cookies from 'js-cookie';


const dataFetchReducer = (statePatch, action) => {
    switch (action.action.type) {
      case 'FETCH_INIT':
        return {
          ...statePatch,
          isLoading: true,
          hasError: false
        };
      case 'FETCH_SUCCESS':
        return {
          ...statePatch,
          isLoading: false,
          hasError: false,
          data: action.action.data,
          status : action.action.status,
          afterfetch : action.action.afterfetch
        };
      case 'FETCH_FAILURE':
        return {
          ...statePatch,
          isLoading: false,
          hasError: true,
          errorMessage : action.action.errorMessage,
          status : action.action.status,
          afterfetch : action.action.afterfetch
        };
      default:
        throw new Error();
    }
};

const usePatchData = (initialUrl, initialPayload, isCsrf,withHeaders) => {
    const [url, setPatchUrl] = useState(initialUrl)
    const [ refresh, setRefresh ] = useState(null)
    const [payloadPatch, setPayloadPatch] = useState(initialPayload)
    const [statePatch, dispatch] = useReducer(dataFetchReducer, {
        isLoading: false,
        hasError: false,
        data: null,
        errorMessage : '',
        status : 0,
        afterfetch : 0,
    });

    const setRefreshPatch = () => {
      setRefresh(Helper.makeid())
    }
    
    useEffect(() => {   
        const csrftoken = Cookies.get('csrftoken')
        const patchData = () => {
            var action = 
            { 
              type : 'FETCH_INIT',
              errorMessage : '',
              status : 0,
              afterfetch : 0,
              data : null
            }
            dispatch({action});
            var pload = {}
            
            var finalpayload = payloadPatch;
            if (finalpayload!==null) {
              if (withHeaders===true || withHeaders===null || withHeaders===undefined) {
                pload = {
                    method: "PATCH",
                    headers : { 'Content-Type' : 'application/json' },
                    body: JSON.stringify(finalpayload),
                }
              }
              else {
                pload = {
                  method: "PATCH",
                  body: JSON.stringify(finalpayload),
                }
              }
            }
            else {
              if (withHeaders===true || withHeaders===null || withHeaders===undefined) {
                pload = {
                    method: "PATCH",
                    headers : { 'Content-Type' : 'application/json' },
                }
              }
              else {
                pload = {
                  method: "PATCH",
                }
              }
            }
            if (finalpayload!==null) {
              if (isCsrf===true || isCsrf===null || isCsrf===undefined) {
                if (csrftoken!==null) {
                    pload = {
                    method: "PATCH",
                        headers: {
                            'Content-Type' : 'application/json',
                            'X-CSRF-Token' : csrftoken,
                        },
                        body: JSON.stringify(finalpayload),
                    }
                }
              }
            }
            else {
              if (isCsrf===true || isCsrf===null || isCsrf===undefined) {
                if (csrftoken!==null) {
                    pload = {
                    method: "PATCH",
                        headers: {
                            'Content-Type' : 'application/json',
                            'X-CSRF-Token' : csrftoken,
                        },
                    }
                }
              }
            }
            return fetch(url, pload)
            .then(r =>  r.json().then((data) => {
                if (r.status!==200 && r.status!==201 && r.status!==304) {
                    action = 
                    { 
                      type : 'FETCH_FAILURE',
                      errorMessage : 'Error',
                      status : 'FAILED',
                      afterfetch : Helper.makeid(),
                      data : null,
                    }
                    dispatch({ action });
                }
                else {
                    action = 
                    { 
                      type: 'FETCH_SUCCESS', 
                      data: data, 
                      status : 'SUCCESS',
                      afterfetch : Helper.makeid(),
                      errorMessage : '',
                    }
                    dispatch({action});
                }
            }))
            .catch((error)=>{
                var action = 
                { 
                  type: 'FETCH_FAILURE', 
                  errorMessage : error.message, 
                  status : 'FAILED',
                  afterfetch : Helper.makeid(),
                  data : null
                }
                dispatch({action});
            })
        }
        if ((url!==null) || refresh!==null)
        patchData()
    }, [url, payloadPatch, refresh])
    
    return { statePatch, setPatchUrl, setPayloadPatch, setRefreshPatch }
}

export default usePatchData