import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import UnprotectedRoutes from './components/UnprotectedRoutes';
import HeaderMenu from './components/HeaderMenu'
//import FooterMenu from './components/FooterMenu';


const UnprotectedContainer = props => {
	const history = useHistory();
  
	useEffect(()=>{
		history.replace('/home2')
	},[])

	return (
		<>
			<HeaderMenu/>
			<UnprotectedRoutes/>
		</>
	)
}

export default UnprotectedContainer


/*
<HeaderMenu/>
				<UnprotectedRoutes/>
			<FooterMenu/>
			*/
