import React from 'react';
import { 
    Grid, 
    Header,
    Button,
    Image,
  } from 'semantic-ui-react';
  import { useHistory } from 'react-router-dom';


const EmptySpace = props => {
    let history = useHistory();
    const {
        title,
        caption,
        image,
        buttonCaption,
        showButton,
        link
    } = props

    const onClick = () => {
        if (link)
            history.push(link)
        else
            props.onClick()
    }

    return (
        <div>
            <Grid style={{ marginTop: "2em",marginBottom: "2em"}}>
                <Grid.Row>
                    <Grid.Column verticalAlign='middle' textAlign='center'>
                        <Image src={image} size='small' />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column verticalAlign='middle' textAlign='center'>
                        <Header as='h3'>{title}</Header>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column verticalAlign='middle' textAlign='center'>
                        {caption}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column verticalAlign='middle' textAlign='center'>
                        <p/>
                    </Grid.Column>
                </Grid.Row>
                {
                    (showButton===true) ?
                    (
                        <Grid.Row>
                            <Grid.Column verticalAlign='middle' textAlign='center'>
                                <Button onClick={onClick}>
                                    {buttonCaption}
                                </Button>    
                            </Grid.Column>
                        </Grid.Row>
                    ) 
                    :
                    (
                        null
                    )
                }
            </Grid>
        </div>
    )
}

export default EmptySpace