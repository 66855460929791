import React, { useEffect, useState } from 'react';
import {
    Header,
    Icon,
    Container,
    Grid,
    Button,
    Label,
    List
} from 'semantic-ui-react';
import LoadingBox from '../components/LoadingBox';
import useParallelGetData from '../services/useParallelGetData';
import usePostData from '../services/usePostData';
import URLLocation from '../services/URLLocation';
import ConfirmationBox from '../components/ConfirmationBox';
import ConfirmationBox2 from '../components/ConfirmationBox';
import ModalSortirGabah from './Modals/ModalSortirGabah';
import Helper from '../services/Helper';
import MessageBox from '../components/MessageBox';
import gabah from './beras.png'
import useAuthContext from '../services/stores/useAuthContext';
import Cookies from 'js-cookie';
import EmptySpace from '../components/EmptySpace'

const SortirGabah = props => {
    const { stateGetParallel, setGetUrls, setRefreshParallelGet } = useParallelGetData(null)
    const { statePost, setRefreshPost, setPayload } = usePostData(URLLocation.getUrl() + '/apiturus/v1/saveturus',null)
    const [ filtered, setFiltered ] = useState(false)
    const [ openConfirm, setOpenConfirm ] = useState(false)
    const [ openConfirm2, setOpenConfirm2 ] = useState(false)
    const [ lblFilter, setLblFilter ] = useState(null)
    const [ lblNota, setLblNota ] = useState(null)
    const [ lblJam, setLblJam ] = useState(null)
    const [ openModal, setOpenModal ] = useState(false)
    const [ dataSource1, setDataSource1 ] = useState(null)
    const [ dataSource2, setDataSource2 ] = useState(null)
    const [ message, setMessage ] = useState(null)
    const [ openError, setOpenError ] = useState(false)
    const [ qtyTotal, setQtyTotal ] = useState(0)
    const [ totalBerat, setTotalBerat ] = useState(0)
    const [ beratZak, setBeratZak ] = useState(0)
    const [ params, setParams ] = useState(null)
    const { stateContext } = useAuthContext()

    const filterClick = () => {
        setOpenModal(true)
    }

    const handleConfirm = () => {
        setFiltered(false)
        setDataSource1(null)
        setDataSource2(null)
        setLblFilter(null)
        setLblNota(null)
        setLblJam(null)
        setOpenConfirm(false)
    }

    const handleConfirm2 = () => {
        setOpenConfirm2(false)
        const dt = JSON.parse(Cookies.get('bpr-terminalinfo'))
        var details = []
        for(var i=0;i<dataSource2.length;i++) {
            details.push({
                productid : dataSource1[i].id,
                productname : dataSource1[i].name,
                turusqty : dataSource2[i] 

            })
        }
        setPayload(
            {
                trxdate : params.datetrx,
                trxend : new Date(),
                gudang : params.gudang,
                rombongan : params.rombongan,
                isitotal : params.isitotal,
                qty : params.qty,
                berat : params.berat,
                supplierid : params.supplier,
                suppliername : params.suppliertext,
                trukno : params.nokend,
                truktype : params.jenistruk,
                status : 1,
                antrianno : params.nota,
                details : details,
                username : stateContext.id,
                terminalcode : dt.terminal,
                sitename : dt.site,
                productid : params.jenisgabah,
                productname : params.jenisgabahtext,
                nota : params.notatext
            }
        )
        setRefreshPost()
    }

    useEffect(()=>{
        if (stateGetParallel.status==='SUCCESS') {
            setDataSource1(stateGetParallel.data[0])
            var arr = []
            for(var i=0;i<stateGetParallel.data[0].length;i++) {
                arr.push(0)
            }
            setDataSource2(arr)
        }
        else {
            if (stateGetParallel.status==='FAILED') {
                setOpenError(true)
                setMessage('Error mengambil data')
            }
        }
    },[stateGetParallel.afterfetch])

    useEffect(()=>{
        if (statePost.status==='SUCCESS') {
            setFiltered(false)
            setDataSource1(null)
            setDataSource2(null)
            setLblFilter(null)
            setLblNota(null)
            setLblJam(null)
        }
        else {
            if (statePost.status==='FAILED') {
                setOpenError(true)
                setMessage('Error menyimpan data')
            }
        }
    },[statePost.afterfetch])

    const refreshData = (pars) => {
        setParams(pars)
        setFiltered(true)
        setOpenModal(false)
        const str = pars.suppliertext + ', ' + pars.nokend + 
        ',      PRODUK : ' + pars.jenisgabahtext// + ' - (satuan ' + pars.berat + 'kg, total : ' + pars.isitotal + ' kg)'
        setLblFilter(str)
        setLblNota('NOTA : ' + pars.notatext)
        setLblJam('JAM : ' + Helper.formatDate(pars.jammulai))
        setTotalBerat(pars.qty)
        setQtyTotal(0)
        setBeratZak(0)
        const urls = [
            URLLocation.getUrl() + '/apiturus/v1/getsubitems/' + pars.jenisgabah
        ]
        setGetUrls(urls)
        setRefreshParallelGet()
    }

    const clearFilterClick = () => {
        setOpenConfirm(true)
    }

    const saveClick = () => {
        if (beratZak<=0) {
            setMessage('Zak tidak boleh nol')
            setOpenError(true)
        }
        else
            setOpenConfirm2(true)
    }

    const addItem = (index) => {
        var arr = dataSource2.slice()
        //var ttl = parseFloat(totalBerat) + parseFloat((3*params.berat))
        //if (parseFloat(qtyTotal*params.berat)<ttl) {
        arr[index] = arr[index] + 1
        var qty = qtyTotal
        qty = qty + 1
        //if (qty*params.berat>params.isitotal) {
        //    setMessage('Berat melebihi berat timbangan')
        //    setOpenError(true)
        //}
        //else {
            setBeratZak(qty*params.berat)
            setQtyTotal(qty)
            //}
            setDataSource2(arr)
        //}
    }

    const removeItem = (index) => {
        var arr = dataSource2.slice()
        if (arr[index]>0) {
            arr[index] = arr[index] - 1
            var qty = qtyTotal
            qty = qty - 1
            setBeratZak(qty*params.berat)
            setQtyTotal(qty)
        }
        setDataSource2(arr)
    }


    return (
        <>
            {(stateGetParallel.isLoading || statePost.isLoading) ?
                <LoadingBox />
                :
                <Container style={{ paddingLeft: '1em'}}>
                    <MessageBox open={openError} message={message} onClose={()=>setOpenError(false)}/>
                    <ModalSortirGabah filtered={filtered} parfilters={params} open={openModal} refreshData={refreshData} onClose={()=>setOpenModal(false)}/>
                    <ConfirmationBox open={openConfirm} message='Hapus data?' onYes={handleConfirm} onNo={()=>setOpenConfirm(false)}/>
                    <ConfirmationBox2 open={openConfirm2} message='Simpan data?' onYes={handleConfirm2} onNo={()=>setOpenConfirm2(false)}/>
                    <Grid padded verticalAlign='middle'>
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <Header as='h2'>
                                    <img
                                        floated='left'
                                        src={gabah}
                                    />
                                    Sortir Gabah/Beras
                                </Header>
                            </Grid.Column>
                            <Grid.Column textAlign='right'>
                                {(filtered) ?
                                    <Label size='large'>{lblJam}</Label>
                                    :
                                    null
                                }
                            </Grid.Column>
                        </Grid.Row>
                        {(filtered) ?
                            <>
                                <Grid.Row columns={2}>
                                    <Grid.Column>
                                        <Label color='orange' as='a' onClick={()=>setOpenModal(true)} size='large'>
                                            {lblNota} 
                                        </Label><br/>
                                        <Label size='large'>
                                            {lblFilter} 
                                        </Label>
                                    </Grid.Column>
                                    <Grid.Column textAlign='right'>
                                        <Label circular color='blue' size='huge'>{qtyTotal} ZAK</Label>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns={2}>
                                    <Grid.Column>
                                        <Button icon labelPosition='left' onClick={clearFilterClick}>
                                            <Icon name='close'/>
                                            Clear
                                        </Button>
                                    </Grid.Column>
                                    <Grid.Column textAlign='right'>
                                        <Button primary icon labelPosition='left' onClick={saveClick}>
                                            <Icon name='check'/>
                                            Simpan
                                        </Button>
                                    </Grid.Column>
                                </Grid.Row>
                            </>
                            :
                            <>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Button onClick={filterClick}>Pilih nota timbang</Button>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column textAlign='center'>
                                        <EmptySpace
                                            title='Tidak ada data'
                                            caption='Tidak ada data. Pilih nota timbang dahulu'
                                            image={null}
                                            buttonCaption={null}
                                            showButton={false}
                                            link={null}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </>
                        }
                        <Grid.Row>
                            <Grid.Column>
                                <List divided>
                                {dataSource1 &&
                                    dataSource1.map((obj1,index) =>{
                                        return( 
                                            
                                                <List.Item>
                                                    <List.Content floated='right'>
                                                        <Button size='medium' onClick={()=>addItem(index)}>+</Button>
                                                        <Button size='medium' onClick={()=>removeItem(index)}>-</Button>
                                                        &nbsp;&nbsp;&nbsp;
                                                        <Label circular color={(dataSource2[index]<=0) ? 'grey' : 'blue'} size='big'>
                                                            <b>{(dataSource2) ? dataSource2[index]: null}</b>
                                                        </Label>
                                                        &nbsp;&nbsp;&nbsp;
                                                    </List.Content>
                                                    <List.Icon name='box' size='large' verticalAlign='middle' />
                                                    <List.Content>
                                                        {obj1.name}
                                                    </List.Content>
                                                </List.Item>
                                           
                                        )
                                    })}
                                </List>
                            </Grid.Column>
                        </Grid.Row>           
                    </Grid>
                </Container>
            }
        </>
    )
}

export default SortirGabah

/*
<Grid.Column textAlign='right'>
                                        <Label circular color='blue' size='huge'>{qtyTotal} ZAK</Label>
                                        <Label circular color='blue' size='huge'>{beratZak} kg</Label>
                                    </Grid.Column>

                                    */