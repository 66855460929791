import React from 'react';
import {
    Grid,
    Label
} from 'semantic-ui-react';
import { Link } from 'react-router-dom'
//import { useHistory } from 'react-router-dom'
import URLLocation from '../services/URLLocation'; 

const Logout = () => {
    //const history = useHistory()

    const linkOnClick = () =>{
        //history.replace('/home2')
        window.location.href = URLLocation.getUrlAuth() + '/?original_appid=88'
    }

    return (
    
        <Grid stackable textAlign='center' style={{ marginBottom: '15em',marginTop: '3em'}}>
            <Grid.Row verticalAlign='middle'>
                <Grid.Column width={4}/>
                <Grid.Column width={8}>
                    <Label as={Link} onClick={linkOnClick}>Click here to sign in again</Label>
                </Grid.Column>
                <Grid.Column width={4}/>
            </Grid.Row>
        </Grid>
    )
}

export default Logout
