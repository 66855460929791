import { useState, useEffect, useReducer } from 'react'
import Helper from './Helper';
import Cookies from 'js-cookie';

const dataFetchReducer =(stateGet, action) => {
    switch (action.action.type) {
      case 'FETCH_INIT':
        return {
          ...stateGet,
          isLoading: true,
          hasError: false
        };
      case 'FETCH_SUCCESS':
        return {
          ...stateGet,
          isLoading: false,
          hasError: false,
          data: action.action.data,
          status : action.action.status,
          afterfetch : action.action.afterfetch,
        };
      case 'FETCH_FAILURE':
        return {
          ...stateGet,
          isLoading: false,
          hasError: true,
          errorMessage : action.action.errorMessage,
          status : action.action.status,
          afterfetch : action.action.afterfetch,
        };
      default:
        throw new Error();
    }
};

const useGetData = (initialUrl,isCsrf, withHeaders) => {
    const [url, setGetUrl] = useState(initialUrl)
    const [refresh, setRefresh] = useState(null)
    const [stateGet, dispatch] = useReducer(dataFetchReducer, {
        isLoading: false,
        hasError: false,
        data: null,
        errorMessage : '',
        status : 0,
        afterfetch : 0,
    });

    const setRefreshGet = () =>{
      setRefresh(Helper.makeid())
    }

    useEffect(() => {   
        const csrftoken = Cookies.get('csrftoken')
        const fetchData = () => {
            var action = 
            { 
              type : 'FETCH_INIT',
              errorMessage : '',
              status : 0,
              data : null,
              afterfetch : 0
            }
            dispatch({action});
            var pload = {}
            if (withHeaders===true || withHeaders===null || withHeaders===undefined) {
              pload = {
                  method: "GET",
                  headers : { 'Content-Type' : 'application/json' }
              }
            }
            else {
              pload = {
                method: "GET",
              }
            }
            if (isCsrf===true || isCsrf===null || isCsrf===undefined) {
              if (csrftoken!==null) {
                pload =  {
                    method: "GET",
                    headers: {
                        'Content-Type' : 'application/json',
                        'X-CSRF-Token' : csrftoken
                    },
                }
              }
            }
           
            return fetch(url, pload
            )
            .then(r =>  r.json().then((data) => {
                if (r.status!==200 && r.status!==201 && r.status!==304) {
                    action = { type: 'FETCH_FAILURE' , errorMessage : 'Error', status : r.status, data:null, afterfetch : Helper.makeid()};
                    dispatch({action});
                }
                else {
                 
                    action = 
                    { 
                      type: 'FETCH_SUCCESS', 
                      data: data, 
                      status : 'SUCCESS', 
                      afterfetch : Helper.makeid(),
                      errorMessage : '',
                    };
                    dispatch({action});
                }
            }))
            .catch((err)=> {
              alert(err)
            })
        }
        if (url!==null && url!=='') {
          if (refresh!==null || stateGet.data===null) fetchData()
        }
    }, [url,refresh])
    return { stateGet, setGetUrl, setRefreshGet }
}

export default useGetData