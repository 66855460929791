import React, { useEffect, useState } from 'react';
import URLLocation from './services/URLLocation';
import usePostData from './services/usePostData';
import useAuthContext from './services/stores/useAuthContext'
import ProtectedRoutes from './components/ProtectedRoutes';
import Cookies from 'js-cookie';
import AppBar from './components/AppBar';
import { useHistory } from 'react-router-dom';
import LoadingBox from './components/LoadingBox'
import AppMenus from './services/AppMenus';

  
const LoginProcess = props => {
	const history = useHistory()
	const { dispatch } = useAuthContext();
	const { statePost, setPayload } = usePostData(
		URLLocation.getUrl() + '/getuserinfobytoken', null
	);
	const [ sidebarmenus, setSidebarmenus ] = useState(null)


	useEffect(()=>{
		const data = {
			token : props.code,
            appid : 0
		}
		setPayload(data)	
	},[])

	useEffect(()=>{
		if (statePost.status==='SUCCESS') {
			if (statePost.data.posst==='INFORMATION & TECHNOLOGY DEVELOPMENT OFFICER')
				setSidebarmenus(AppMenus.getSalesMenus())
			else 
				setSidebarmenus(AppMenus.getDeliveryMenus())
			Cookies.set('content-cred', JSON.stringify(statePost.data),{ expires: 7 })
			dispatch({
				type: "LOGIN",
				payload: statePost.data
			});
			history.replace('/home')
		}	
	},[statePost.afterfetch])

	return (
		<>
			{(statePost.isLoading || sidebarmenus===null) ?	
				<LoadingBox/>
			:
				<>
					<AppBar
						username={statePost.data.partner_name}
						sidebarmenus={sidebarmenus}
					>
						<ProtectedRoutes/>
						&nbsp;<p/>
					</AppBar>
				</>
			}
		</>
	)
}

export default LoginProcess
