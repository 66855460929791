import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home2 from '../pages/Home2';
import NotFound from '../pages/NotFound';
import { useLocation } from 'react-router';
import Logout from '../pages/Logout'

const UnprotectedRoutes = props => {
	const location = useLocation()
	  	return (
			<Switch>
				<Route exact path='/' key={location.key} component={Home2} />
				<Route exact path='/home2' key={location.key} component={Home2} />
				<Route exact path='/logout' key={location.key} component={Logout} />
				<Route component={NotFound} />
			</Switch>
	  	);

}

export default UnprotectedRoutes