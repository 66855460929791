import React from 'react';
import { Button, Menu } from 'semantic-ui-react';
//import { Link } from "react-router-dom";

const HeaderMenu = () => {

	return (
		<>
			<Menu borderless size='mini'  fixed='top'>
				<Menu.Item name='menutop_seekr' style={{ fontSize: '2em' }}>
				{/*<Image size='mini' src='/logo.png' style={{ marginRight: '1.5em' }} />*/}
					<strong>Aplikasi Sortir Gabah dan Beras</strong>
				</Menu.Item>
				
			</Menu>
		</>
	);
}

export default HeaderMenu;

/*
<Menu.Item as={ Link } to={'/'} name='menutop_companies' style={{ fontSize: '1.33em' }}>
					<strong>Home</strong></Menu.Item>
					*/