class helper {
    formatDate(dtd) {
        var dtx = null;
        if (dtd) {
            var dt = new Date(dtd);
            var dtm = (dt.getMonth()+1).toString();
            var dtt = dt.getDate().toString();
            if (dtt.length===1) dtt = '0' + dtt;
            if (dtm.length===1) dtm = '0' + dtm;
            var hours = dt.getHours();
            var minutes = dt.getMinutes();
            var ampm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            hours = hours < 10 ? '0' +hours : hours;
            minutes = minutes < 10 ? '0'+minutes : minutes;
            var strTime = hours + ':' + minutes + ' ' + ampm;
            dtx = dt.getFullYear().toString() + '-' + dtm + '-' + dtt + ' ' + strTime;
        }
        return dtx;
    }
    
    differenceDate(dt) {
        var timeDiff = Math.abs(new Date().getTime() - new Date(dt).getTime());
        var diffDaysX = Math.ceil(timeDiff / (1000 * 3600)); 
        var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)); 
        var ret = '';
        if (diffDaysX<=24) ret = diffDaysX + 'h'
        if (diffDaysX>24 && diffDays<=30) ret =  diffDays + 'd'
        if (diffDaysX>24 && diffDays>30) ret = '30d+'
        return ret
    }

    diffDateInt(dt) {
        var timeDiff = Math.abs(new Date().getTime() - new Date(dt).getTime());
        var diffDaysX = Math.ceil(timeDiff / (1000 * 3600)); 
        var ret = '';
        if (diffDaysX<=24) ret = 0
        if (diffDaysX>24) ret = 1
        return ret
    }

    formatNumber(num) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    filterByValue(array, string) {
        return array.filter(o =>
            Object.keys(o).some(k => o[k].toLowerCase().includes(string.toLowerCase())));
    }

    formatDateShort(dtd) {
        var dtx = null;
        if (dtd) {
            var dt = new Date(dtd);
            var dtm = (dt.getMonth()+1).toString();
            var dtt = dt.getDate().toString();
            if (dtt.length===1) dtt = '0' + dtt;
            if (dtm.length===1) dtm = '0' + dtm;
            dtx = dt.getFullYear().toString() + '-' + dtm + '-' + dtt;
        }
        return dtx;
    }

    makeid() {
        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        for (var i = 0; i < 8; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        return text;
    }

    /*
        getImageUrl() {
            return 'https://pdx.co.id:1338/dxl.jpg';
        }

        getDefaultUserURL() {
            return '../img/user.png';
        }
    */  
}

const Helper = new helper();  
export default Helper;