export const reducerAdmin = (stateAdminContext, action) => {
    switch (action.type) {
        case "LOGIN":
            return {
                ...stateAdminContext,
                isAdmin: action.payload.isadmin, 
            };
        case "LOGOUT":
            return {
                ...stateAdminContext,
                isAdmin: false, 
            };
        default:
            return stateAdminContext;
      }
}