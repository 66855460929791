import React from 'react';
import { Container } from 'semantic-ui-react';

const NotFound = props => {

    
    return (
       <Container>
           <div>
               Not found
           </div>
       </Container>
    );
}

export default NotFound;