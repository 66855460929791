import React, { useReducer } from 'react';
import './App.css';
import 'semantic-ui-css/semantic.min.css'
import queryString from 'query-string';
import AppInit from './AppInit';
import { BrowserRouter } from 'react-router-dom';
import { AuthContext } from './services/stores/AuthContext';
import { AdminContext } from './services/stores/AdminContext';
import { initialState } from './services/stores/initialState';
import { initialAdminState } from './services/stores/initialAdminState';
import { reducer } from './services/stores/reducer';
import { reducerAdmin } from './services/stores/reducerAdmin'
import Cookies from 'js-cookie';


function App() {
  const [ stateContext, dispatch ] = useReducer(reducer, initialState);
  const [ stateAdminContext, dispatchAdmin ] = useReducer(reducerAdmin,initialAdminState)
  const content = Cookies.get('bpr-terminalinfo')
  let params = queryString.parse(window.location.search)
  
  const { 
    kpitoken, 
  } = params;
    
  return (
      <BrowserRouter>
        <AuthContext.Provider
            value={{
              stateContext,
              dispatch
            }}
        >
          <AdminContext.Provider
            value={{
              stateAdminContext,
              dispatchAdmin
            }}
          >
            <AppInit
              code={kpitoken} 
              content={content} 
            />
          </AdminContext.Provider>
        </AuthContext.Provider>
      </BrowserRouter>
    )
  }

export default App;
