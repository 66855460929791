class urlLocation {
    getUrl() {
        //return 'http://localhost:1338'
        //return 'http://108.137.170.97:1338';
        return 'https://sortirapps.sep-food.com:1338';
    }

    getUrlAuth() {
        //return 'http://hai.sep-food.com/authapp';
        //return 'http://108.137.170.97/authapp';
        return 'https://sortirapps.sep-food.com/authapp';
    }
}

const URLLocation = new urlLocation();  
export default URLLocation;
