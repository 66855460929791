import React, {useEffect, useState, useRef} from 'react';
import { 
    Modal,
    Grid,
    Dropdown,
    Input,
    Button
} from 'semantic-ui-react';

import useParallelGetData from '../../services/useParallelGetData';
import useGetData from '../../services/useGetData';
import URLLocation from '../../services/URLLocation'
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import Cookies from 'js-cookie';


const ModalSortirGabah = props => {
    const info = JSON.parse(Cookies.get('bpr-terminalinfo'))
    const { open, filtered, parfilters } = props
    const isMounted = useRef(false)
    const [ mode, setMode ] = useState(1)
    const { stateGet, setGetUrl, setRefreshGet } = useGetData(null)
    const { stateGetParallel, setGetUrls, setRefreshParallelGet } = useParallelGetData(null)
    const [ notas, setNotas ] = useState(null)
    const [ dataJenisTruk, setDataJenisTruk ] = useState(null)
    const [ dataWarehouses, setDataWarehouses ] = useState(null)
    const [ selectedSupplier, setSelectedSupplier ] = useState((props.parfilters) ? props.parfilters.supplier : null)
    const [ selectedJenisTruk, setSelectedJenisTruk ] = useState((props.parfilters) ? props.parfilters.jenistruk : null)
    const [ selectedJenisGabah, setSelectedJenisGabah ] = useState((props.parfilters) ? props.parfilters.jenisgabah : null)
    const [ selectedSupplierText, setSelectedSupplierText ] = useState((props.parfilters) ? props.parfilters.suppliertext : null)
    const [ selectedJenisTrukText, setSelectedJenisTrukText ] = useState((props.parfilters) ? props.parfilters.jenistruktext :null)
    const [ selectedJenisGabahText, setSelectedJenisGabahText ] = useState((props.parfilters) ? props.parfilters.jenisgabahtext : null)
    const [ nokend, setNokend ] = useState((props.parfilters) ? props.parfilters.nokend : null)
    const [ berat, setBerat ] = useState((props.parfilters) ? props.parfilters.berat : null)
    const [ qty, setQty ] = useState((props.parfilters) ? props.parfilters.qty : null)
    const [ gudang, setGudang ] = useState((props.parfilters) ? props.parfilters.gudang : null)
    const [ rombongan, setRombongan ] = useState((props.parfilters) ? props.parfilters.rombongan : null)
    const [ openError, setOpenError ] = useState(false)
    const [ notaText, setNotaText ] = useState((props.parfilters) ? props.parfilters.notatext : null)
    const [ nota, setNota ] = useState((props.parfilters) ? props.parfilters.nota : null)
    const [ dateTrx,setDateTrx ] = useState((props.parfilters) ? props.parfilters.datetrx : null)
    const [ gabahs, setGabahs ] = useState(null)
    const dataBerat = [
        { 
            text : '10kg',
            value : 10
        },
        { 
            text : '20kg',
            value : 20
        },
        { 
            text : '30kg',
            value : 30
        },
        { 
            text : '40kg',
            value : 40
        },
        { 
            text : '50kg',
            value : 50
        },
        { 
            text : '60kg',
            value : 60
        },
        { 
            text : '70kg',
            value : 70
        },
        { 
            text : '100kg',
            value : 100
        },
    ]

    const sortData = (arr, asc) => {
        if (asc===true) {
            var ret = arr.sort((a, b) => {
                if (a.text < b.text) {
                return -1;
                }
            });
            return ret
        }
        else {
            var ret = arr.sort((a, b) => {
                if (a.text > b.text) {
                return -1;
                }
            });
            return ret
        }
    }
    
    useEffect(()=>{
        if (isMounted.current) {
            if (stateGet.status==='SUCCESS') {
                if (mode===1) {
                    var arr = []
                    for(var i=0;i<stateGet.data.length;i++) {
                        arr.push({
                            text: stateGet.data[i].name,
                            value : stateGet.data[i].id
                        })
                    }
                    setNotas(arr)
                } 
                else {
                    if (stateGet.data.length>0) {
                        setSelectedSupplier(stateGet.data[0].supplierid)
                        setSelectedSupplierText(stateGet.data[0].suppliername)
                        var arr1 = []
                        arr1.push({value : stateGet.data[0].productid, text :stateGet.data[0].productname})
                        var arr2 = []
                        arr2.push(stateGet.data[0].productid)
                        setSelectedJenisGabah(arr2)
                        var str = getText(arr1,stateGet.data[0].productid)
                        str = str.substring(2)
                        setSelectedJenisGabahText(str)
                        
                        setQty(stateGet.data[0].timbang)
                        setRombongan('Default')
                        setNokend(stateGet.data[0].kendaraan)
                        setDateTrx(stateGet.data[0].startdate)
                    }
                    else {
                        setSelectedSupplier(null)
                        setSelectedSupplierText(null)
                        setSelectedJenisGabah(null)
                        setSelectedJenisGabahText(null)
                        setQty(null)
                        setNokend(null)
                        setDateTrx(null)
                    }
                    //setGudang(info.site)
                }
                const urls = [
                    URLLocation.getUrl() + '/apiturus/v1/getjenistruk',
                    URLLocation.getUrl() + '/apiturus/v1/getwarehouses/' + info.site,
                    URLLocation.getUrl() + '/apiturus/v1/getitemsgabah'
                ]
                setGetUrls(urls)
                setRefreshParallelGet()
            }
        }
    },[stateGet.afterfetch])

    useEffect(()=>{
        if (isMounted.current)
            if (stateGetParallel.status==='SUCCESS') {
               
                var jsonArr3 = []
                for(var i=0;i<stateGetParallel.data[0].length;i++) {
                    jsonArr3.push({
                        text: stateGetParallel.data[0][i].name,
                        value : stateGetParallel.data[0][i].id
                    })
                }
                setSelectedJenisTruk(1)
                setSelectedJenisTrukText(findText(jsonArr3,1))
                setDataJenisTruk(jsonArr3)
                var jsonArr4 = []
                for(var i=0;i<stateGetParallel.data[1].length;i++) {
                    jsonArr4.push({
                        text: stateGetParallel.data[1][i].name,
                        value : stateGetParallel.data[1][i].id
                    })
                }
                setDataWarehouses(jsonArr4)

                var jsonArr5 = []
                for(var i=0;i<stateGetParallel.data[2].length;i++) {
                    jsonArr5.push({
                        text: stateGetParallel.data[2][i].name,
                        value : stateGetParallel.data[2][i].id
                    })
                }
                setGabahs(sortData(jsonArr5,true))
            }
    },[stateGetParallel.afterfetch])

    useEffect(()=>{
        if (isMounted.current) {
            if (open===true) {
                setGetUrl(URLLocation.getUrl() + '/apiturus/v1/getnotatimbang/' + info.site)
                setRefreshGet()
                setMode(1)
                if (filtered===false) {
                    setSelectedJenisGabah(null)
                    setSelectedJenisGabahText(null)
                    setSelectedJenisTruk(null)
                    setSelectedJenisTrukText(null)
                    setSelectedSupplier(null)
                    setSelectedSupplierText(null)
                    setBerat(null)
                    setNokend(null)
                    setQty(null)
                    setGudang(null)
                    setRombongan(null)
                    setNota(null)
                    setDateTrx(null)
                }
            }
        }
    },[open])

    useEffect(()=>{
        isMounted.current = true
        setRombongan('Default')
    },[])

    const buttonClick = () => {
        if (nota===null || nota===undefined) { setOpenError(true); return;}
        if (selectedSupplier===null || selectedSupplier===undefined) { setOpenError(true); return;}
        if (selectedJenisGabah===null || selectedJenisGabah===undefined) { setOpenError(true); return;}
        if (selectedJenisGabahText==='null' || selectedJenisGabahText===null || selectedJenisGabahText===undefined) { setOpenError(true); return;}
        if (berat===null || berat===undefined) { setOpenError(true); return;}
        if (selectedJenisTruk===null || selectedJenisTruk===undefined) { setOpenError(true); return;}
        if (nokend==='' || nokend===null) { setOpenError(true); return;}
        if (gudang==='' || gudang===null) { setOpenError(true); return;}
        if (rombongan==='' || rombongan===null) { setOpenError(true); return;}
        var pars = {
            supplier : selectedSupplier,
            suppliertext : selectedSupplierText,

            jenisgabah : selectedJenisGabah,
            jenisgabahtext : selectedJenisGabahText,
            
            jenistruk : selectedJenisTruk,
            jenistruktext : selectedJenisTrukText,

            datetrx : dateTrx,
            
            nokend : nokend,
            berat : berat,
            qty : qty,
            gudang : gudang,
            rombongan : rombongan,
            isitotal : qty,
            jammulai : new Date(),
            nota : nota,
            notatext : notaText
        }
        props.refreshData(pars)
    }

    const findText = (objs,val) => {
        var found = false
        var i = 0
        while (i<objs.length && found===false) {
            if (String(objs[i].value)===String(val)) {
                found = true
                return objs[i].text 
            }
            i++
        }
        return null
    }

    const onDropdownChange2 = (event, {value}) => {
        setSelectedJenisTruk(value)
        setSelectedJenisTrukText(findText(dataJenisTruk,value))
    }


    const onDropdownChange5 = (event, {value}) => {
        if (value!==null) {
            var text = findText(notas,value)
            if (text!==null && text!=='null') {
                setNota(value)
                setNotaText(text)
                setGetUrl(URLLocation.getUrl() + '/apiturus/v1/gettimbanganbynota/' + text)
                setRefreshGet()
                setMode(2)
            }
        }
    }

    const onDropdownChange6 = (event, {value}) => {
       setGudang(value)
    }

    const onDropdownChange7 = (event, {value}) => {
        setSelectedJenisGabah(value)
        var str = getText(gabahs,value)
        str = str.substring(2)
        setSelectedJenisGabahText(str)
    }

    const getText = (arr,vals) => {
        var arrtext = String(vals).split(',')
        var str = ''
        for(var i=0;i<arrtext.length;i++) {
            var ret = findText(arr,arrtext[i])
            str += ', ' + ret
        }
        return str
    }


    const handleChange5 = (event,{value}) => {
        setRombongan(value)
    }

    const onDropdownChange2a = (event, {value}) => {
        setBerat(value)
    }


    return (
        <>
            <MessageBox open={openError} message={'Data tidak lengkap'} onClose={()=>setOpenError(false)}/>
            <Modal
                size='small'
                open={open}
            >
                
                <Modal.Header>Supplier</Modal.Header>
                {
                (stateGetParallel.isLoading===true || stateGet.isLoading) ? 
                    <LoadingBox/> 
                    :
                    <Modal.Content>
                        <Grid stackable>
                            <Grid.Row>
                                <Grid.Column>
                                    <label>Nota timbang</label>
                                    <Dropdown
                                        name={'notas'}
                                        label={'Nota Timbang'}
                                        fluid
                                        onChange={onDropdownChange5}
                                        options={notas}
                                        selection
                                        search
                                        clearable
                                        value={nota}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={2} style={{ paddingTop: '0.5em', paddingBottom: '0.5em' }}>
                                <Grid.Column>
                                    <label>Supplier</label><br/>
                                    <label><b>{selectedSupplierText}</b></label>
                                </Grid.Column>
                                <Grid.Column>
                                    <label>Nomor kendaraan / Netto (kg)</label><br/>
                                    <label><b>{((nokend===null) ? '' : nokend) + ' /'  + ((qty===null) ? '' : qty + ' kg')}</b></label>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={2}>
                                <Grid.Column>
                                    <label>Jenis Truk</label>
                                    <Dropdown
                                        name={'jenistruk'}
                                        label={'Jenis Truk'}
                                        fluid
                                        onChange={onDropdownChange2}
                                        options={dataJenisTruk}
                                        selection
                                        search
                                        clearable
                                        value={selectedJenisTruk}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <label>Berat Satuan (kg)</label>
                                    <Dropdown
                                        name={'berat'}
                                        label={'Berat Satuan (kg)'}
                                        fluid
                                        onChange={onDropdownChange2a}
                                        options={dataBerat}
                                        selection
                                        search
                                        clearable
                                        value={berat}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <label>Gabah/Beras</label>
                                    <Dropdown
                                        name={'gabahs'}
                                        label={'Gabah/Beras'}
                                        fluid
                                        multiple
                                        onChange={onDropdownChange7}
                                        options={gabahs}
                                        selection
                                        search
                                        clearable
                                        value={selectedJenisGabah}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={2}>
                                <Grid.Column>
                                    <label>Gudang</label>
                                    <Dropdown
                                        name={'Gudang'}
                                        label={'Gudang'}
                                        fluid
                                        onChange={onDropdownChange6}
                                        options={dataWarehouses}
                                        selection
                                        search
                                        clearable
                                        value={gudang}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <label>Rombongan</label>
                                    <Input
                                        name={'rombongan'}
                                        fluid
                                        placeholder='Rombongan'
                                        value={rombongan}
                                        onChange={handleChange5} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Modal.Content>
                    
                }
                <Modal.Actions>
                    <Button primary onClick={buttonClick}>Save</Button>
                    <Button primary onClick={props.onClose}>Close</Button>
                </Modal.Actions>
            </Modal>
        </>
    );
}

export default ModalSortirGabah;


/*
 <label><b>{Helper.formatNumber(String((qty) ? qty : ''))}</b></label>
 <Grid.Row columns={2}>
                                <Grid.Column>
                                    <label>Berat Satuan (kg)</label>
                                    <Input
                                        name={'berat'}
                                        fluid
                                        placeholder='Berat Satuan (kg)'
                                        value={berat}
                                        onChange={handleChange2} />
                                </Grid.Column>
                                <Grid.Column>
                                    <label>Total Berat Isi (kg)</label><br/>
                                    <label><b>{Helper.formatNumber(String((qty) ? qty : ''))}</b></label>
                                </Grid.Column>
                            </Grid.Row>
 <label>Jenis Gabah/Beras</label><p/>
                                    <label><b>{selectedJenisGabahText}</b></label>
                                    */
