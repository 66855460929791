import React, { useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom'
import ProtectedRoutes from './components/ProtectedRoutes';
import AppBar from './components/AppBar';
import useAuthContext from './services/stores/useAuthContext';
import AppMenus from './services/AppMenus';
import LoadingBox from './components/LoadingBox';

const ProtectedContainer = props => {
	const { stateContext } = useAuthContext();
	const history = useHistory();
	const [ sidebarmenus, setSidebarmenus ] = useState(null)
	
	
	useEffect(()=>{
		history.replace('/home')
		if (stateContext.posst==='INFORMATION & TECHNOLOGY DEVELOPMENT OFFICER')
			setSidebarmenus(AppMenus.getSalesMenus())
		else
			setSidebarmenus(AppMenus.getDeliveryMenus())
	},[])

	return (
		<>
			{(sidebarmenus===null) ?
				<LoadingBox/>
				:	
				<AppBar
					username={stateContext.partner_name}
					sidebarmenus={sidebarmenus}
				>
					<ProtectedRoutes/>
					&nbsp;<p/>
				</AppBar>
			}
		</>
	)
}

export default ProtectedContainer