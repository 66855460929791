import React from 'react';
//import HeaderMenu from '../components/HeaderMenu';
//import FooterMenu from '../components/FooterMenu';
import URLLocation from '../services/URLLocation';
import { Segment, Button, Image } from 'semantic-ui-react';
import logoBPR from '../components/logoBPR3.png'


const Home2 = props => {
	//88
	const login = () => {
		//window.location.href = URLLocation.getUrlAuth() + '/?original_appid=101'
		window.location.href = URLLocation.getUrlAuth() + '/?original_appid=88'
	}
	
	return (
		<>		
			<Segment>
				&nbsp;<p/><p/>
    			<Image src={logoBPR} size='tiny' centered />
				<p/>
				<div align='center'>
					<Button onClick={login}>Click to login</Button>
				</div>
  			</Segment>
		</>
	);
}

export default Home2;
