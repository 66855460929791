export const reducer = (stateContext, action) => {
    switch (action.type) {
        case "LOGIN":
            return {
                ...stateContext,
                isLogin: true,
                id: action.payload.id, 
                partner_name : action.payload.partner_name,
                posst : action.payload.posst
            };
        case "LOGOUT":
            return {
                ...stateContext,
                isLogin: false,
                id: null,              
                partner_name : null,
                posst : null
            };
        default:
            return stateContext;
      }
}