import React, {useState,useEffect} from 'react'
import { Modal, Button } from 'semantic-ui-react'
import usePostData from '../../services/usePostData';
import URLLocation from '../../services/URLLocation';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import LoadingBox from '../../components/LoadingBox';
import usePatchData from '../../services/usePatchData';
import useGetData from '../../services/useGetData';

const ModalData = (props) => {
    const { statePatch, setPatchUrl, setPayloadPatch, setRefreshPatch } = usePatchData(null,null)
    const { isEdit, id, open } = props
    const { stateGet, setGetUrl, setRefreshGet } = useGetData(null,null)
    const { statePost, setPayload, setRefreshPost } = usePostData(URLLocation.getUrl() + '/createapp',null)
    const [data, setData] = useState(null)

    useEffect(()=>{
        if (open===true) {
            if (isEdit===true) {
                setGetUrl(URLLocation.getUrl() + '/getappbyid/' + id)
                setRefreshGet()
            }
            else {
                setData(null)
            }
        }
    },[open])

    useEffect(()=>{
        switch (stateGet.status) {
            case 'SUCCESS':
                setData(stateGet.data)
                break;
            case 'FAILED':
                break;
            default:
                break;
        }
    },[stateGet.afterfetch])


    const onSave = () => {
        if (isEdit===true) {
           //setPatchUrl(URLLocation.getUrl() + '/')
           props.onYes()
        }
        else {
            setPayload({
                appname : 'TEST 2',
                appdesc : 'TEST 2',
                appcode : 'TEST2', 
                is_active : 1, 
                dbserver : null, 
                app_url : 'http://localhost:3000'
            })
            setRefreshPost()
        }
    }


    useEffect(()=>{
        if (statePost.status==='SUCCESS') {
            props.onYes()
        }
        
    },[statePost.afterfetch])

    useEffect(()=>{
        if (statePatch.status==='SUCCESS') {
            setPatchUrl(null)
            props.onYes()
        }
    },[statePatch.afterfetch])

    
    return (
        <>
        {(stateGet.isLoading===true || statePost.isLoading===true || statePatch.isLoading===true) ?
            <LoadingBox/>
            :
            <Modal
                size='small'
                open={open}
            >
                <Modal.Header>User</Modal.Header>
                <Modal.Content>
                    <label>{JSON.stringify(data)}</label>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={props.onNo}>Cancel</Button>
                    <Button primary onClick={
                        onSave
                    }>Save</Button>
                </Modal.Actions>
            </Modal>
        }
        </>
	);
}

export default ModalData