import React, {useEffect, useState, useRef} from 'react';
import { 
    Modal,
    Grid,
    Dropdown,
    Input,
    Button
} from 'semantic-ui-react';

import useParallelGetData from '../../services/useParallelGetData';
import URLLocation from '../../services/URLLocation'
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';


const ModalSortirBeras = props => {
    const { open, filtered, parfilters } = props
    const isMounted = useRef(false)
    const { stateGetParallel, setGetUrls, setRefreshParallelGet } = useParallelGetData(null)
    const [ dataSuppliers, setDataSuppliers ] = useState(null)
    const [ dataJenisTruk, setDataJenisTruk ] = useState(null)
    const [ dataJenisBeras, setDataJenisBeras ] = useState(null)
    const [ selectedSupplier, setSelectedSupplier ] = useState((props.parfilters) ? props.parfilters.supplier : null)
    const [ selectedJenisTruk, setSelectedJenisTruk ] = useState((props.parfilters) ? props.parfilters.jenistruk : null)
    const [ selectedJenisBeras, setSelectedJenisBeras ] = useState((props.parfilters) ? props.parfilters.jenisBeras : null)
    const [ selectedSupplierText, setSelectedSupplierText ] = useState((props.parfilters) ? props.parfilters.suppliertext : null)
    const [ selectedJenisTrukText, setSelectedJenisTrukText ] = useState((props.parfilters) ? props.parfilters.jenistruktext :null)
    const [ selectedJenisBerasText, setSelectedJenisBerasText ] = useState((props.parfilters) ? props.parfilters.jenisBerastext : null)
    const [ nokend, setNokend ] = useState((props.parfilters) ? props.parfilters.nokend : null)
    const [ berat, setBerat ] = useState((props.parfilters) ? props.parfilters.berat : null)
    const [ qty, setQty ] = useState((props.parfilters) ? props.parfilters.qty : null)
    const [ gudang, setGudang ] = useState((props.parfilters) ? props.parfilters.gudang : null)
    const [ rombongan, setRombongan ] = useState((props.parfilters) ? props.parfilters.rombongan : null)
    const [ openError, setOpenError ] = useState(false)
    


    useEffect(()=>{
        if (isMounted.current)
            if (stateGetParallel.status==='SUCCESS') {
                var jsonArr1 = []
                for(var i=0;i<stateGetParallel.data[0].length;i++) {
                    jsonArr1.push({
                        text: stateGetParallel.data[0][i].name,
                        value : stateGetParallel.data[0][i].id
                    })
                }
                setDataJenisBeras(jsonArr1)
                
                var jsonArr2 = []
                for(var i=0;i<stateGetParallel.data[1].length;i++) {
                    jsonArr2.push({
                        text: stateGetParallel.data[1][i].name,
                        value : stateGetParallel.data[1][i].id
                    })
                }
                setDataSuppliers(jsonArr2)

                var jsonArr3 = []
                for(var i=0;i<stateGetParallel.data[2].length;i++) {
                    jsonArr3.push({
                        text: stateGetParallel.data[2][i].name,
                        value : stateGetParallel.data[2][i].id
                    })
                }
                setDataJenisTruk(jsonArr3)
            }
    },[stateGetParallel.afterfetch])

    useEffect(()=>{
        if (isMounted.current) {
            if (open===true) {
                const urls = [
                    URLLocation.getUrl() + '/apiturus/v1/getitems',
                    URLLocation.getUrl() + '/apiturus/v1/getsuppliers',
                    URLLocation.getUrl() + '/apiturus/v1/getjenistruk'
                ]
                setGetUrls(urls)
                setRefreshParallelGet()
              
                if (filtered===false) {
                    setSelectedJenisBeras(null)
                    setSelectedJenisBerasText(null)
                    setSelectedJenisTruk(null)
                    setSelectedJenisTrukText(null)
                    setSelectedSupplier(null)
                    setSelectedSupplierText(null)
                    setBerat(null)
                    setNokend(null)
                    setQty(null)
                    setGudang(null)
                    setRombongan(null)
                }
            }
        }
    },[open])

    useEffect(()=>{
        isMounted.current = true
    },[])

    const buttonClick = () => {
        if (selectedSupplier===null || selectedSupplier===undefined) { setOpenError(true); return;}
        if (selectedJenisBeras===null || selectedJenisBeras===undefined) { setOpenError(true); return;}
        if (selectedJenisTruk===null || selectedJenisTruk===undefined) { setOpenError(true); return;}
        if (nokend==='' || nokend===null) { setOpenError(true); return;}
        if (berat==='' || berat===null) { setOpenError(true); return;}
        if (qty==='' || qty===null) { setOpenError(true); return;}
        if (gudang==='' || gudang===null) { setOpenError(true); return;}
        if (rombongan==='' || rombongan===null) { setOpenError(true); return;}
        var pars = {
            supplier : selectedSupplier,
            suppliertext : selectedSupplierText,

            jenisBeras : selectedJenisBeras,
            jenisBerastext : selectedJenisBerasText,
            
            jenistruk : selectedJenisTruk,
            jenistruktext : selectedJenisTrukText,
            
            nokend : nokend,
            berat : berat,
            qty : qty,
            gudang : gudang,
            rombongan : rombongan,
            isitotal : qty,
            jammulai : new Date()
        }
        props.refreshData(pars)
    }

    const findText = (objs,val) => {
        var found = false
        var i = 0
        while (i<objs.length && found===false) {
            if (objs[i].value===val) {
                found = true
                return objs[i].text 
            }
            i++
        }
        return null
    }

    const onDropdownChange1 = (event, {value}) => {
        setSelectedSupplier(value)
        setSelectedSupplierText(findText(dataSuppliers,value))
    }

    const handleChange1 = (event,{value}) => {
        setNokend(value)
    }

    const onDropdownChange2 = (event, {value}) => {
        setSelectedJenisTruk(value)
        setSelectedJenisTrukText(findText(dataJenisTruk,value))
    }

    const onDropdownChange3 = (event, {value}) => {
        setSelectedJenisBeras(value)
        setSelectedJenisBerasText(findText(dataJenisBeras,value))
    }

    const handleChange2 = (event,{value}) => {
        setBerat(value)
    }

    const handleChange3 = (event,{value}) => {
        setQty(value)
    }

    const handleChange4 = (event,{value}) => {
        setGudang(value)
    }

    const handleChange5 = (event,{value}) => {
        setRombongan(value)
    }

    return (
        <>
            <MessageBox open={openError} message={'Data tidak lengkap'} onClose={()=>setOpenError(false)}/>
            <Modal
                size='small'
                open={open}
            >
                
                <Modal.Header>Supplier</Modal.Header>
                {
                (stateGetParallel.isLoading===true) ? 
                    <LoadingBox/> 
                    :
                    <Modal.Content>
                        <Grid stackable>
                            <Grid.Row columns={2} style={{ paddingTop: '0.5em', paddingBottom: '0.5em' }}>
                                <Grid.Column>
                                    <label>Supplier</label><br/>
                                    <Dropdown
                                        name={'supplier'}
                                        label={'Supplier'}
                                        fluid
                                        onChange={onDropdownChange1}
                                        options={dataSuppliers}
                                        selection
                                        search
                                        clearable
                                        value={selectedSupplier}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <label>Nomor kendaraan</label>
                                    <Input
                                        name={'nokendaraan'}
                                        fluid
                                        placeholder='Nomor kendaraan'
                                        value={nokend}
                                        onChange={handleChange1} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={2}>
                                <Grid.Column>
                                    <label>Jenis Truk</label><br/>
                                    <Dropdown
                                        name={'jenistruk'}
                                        label={'Jenis Truk'}
                                        fluid
                                        onChange={onDropdownChange2}
                                        options={dataJenisTruk}
                                        selection
                                        search
                                        clearable
                                        value={selectedJenisTruk}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <label>Jenis Beras</label><br/>
                                    <Dropdown
                                        name={'Beras'}
                                        label={'Jenis Beras'}
                                        fluid
                                        onChange={onDropdownChange3}
                                        options={dataJenisBeras}
                                        selection
                                        search
                                        clearable
                                        value={selectedJenisBeras}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={2}>
                                <Grid.Column>
                                    <label>Berat Satuan (kg)</label>
                                    <Input
                                        name={'berat'}
                                        fluid
                                        placeholder='Berat Satuan (kg)'
                                        value={berat}
                                        onChange={handleChange2} />
                                </Grid.Column>
                                <Grid.Column>
                                    <label>Total Berat (kg)</label>
                                    <Input
                                        name={'qty'}
                                        fluid
                                        placeholder='Total Berat (kg)'
                                        value={qty}
                                        onChange={handleChange3} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={2}>
                                <Grid.Column>
                                    <label>Gudang</label>
                                    <Input
                                        name={'gudang'}
                                        fluid
                                        placeholder='Gudang'
                                        value={gudang}
                                        onChange={handleChange4} />
                                </Grid.Column>
                                <Grid.Column>
                                    <label>Rombongan</label>
                                    <Input
                                        name={'rombongan'}
                                        fluid
                                        placeholder='Rombongan'
                                        value={rombongan}
                                        onChange={handleChange5} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Modal.Content>
                    
                }
                <Modal.Actions>
                    <Button primary onClick={buttonClick}>Save</Button>
                    <Button primary onClick={props.onClose}>Close</Button>
                </Modal.Actions>
            </Modal>
        </>
    );
}

export default ModalSortirBeras;

